import React from 'react';
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

function SplitButton(props) {
    const selectedOption = props.selectedOption;
    const options = Array.isArray(props.options) && props.options.length > 0
        ? props.options
        : [];
    const selectedValue = selectedOption == null ? "..." : selectedOption;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const handleClick = () => {};

    const handleMenuItemClick = (event, option) => {
        if (selectedOption !== option)
            props.onSelectedOptionChange(option);

        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <Grid container>
            <Grid item xs={12} align="center">
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                    <Button onClick={handleClick}>{selectedValue}</Button>
                    <Button
                        color="primary"
                        size="small"
                        disabled={options.length === 1}
                        aria-owns={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} transition style={{zIndex: 1301}}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper id="menu-list-grow">
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList>
                                        {options.map((option, _index) => (
                                            <MenuItem
                                                key={option}
                                                selected={option === selectedOption}
                                                onClick={event => handleMenuItemClick(event, option)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}

export default SplitButton;

SplitButton.propTypes = {
    selectedOption: PropTypes.string,
    options: PropTypes.array.isRequired,
    onSelectedOptionChange: PropTypes.func.isRequired,
};

SplitButton.defaultProps = {
    selectedOption: null,
    options: [],
    onSelectedOptionChange: (_option) => {},
};