import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import CourseFilters from "../models/CourseFilters";
import SplitButton from "./ui/SplitButton";
import CourseTypeFilter from "./CourseTypeFilter";

class CourseFilter extends React.Component {
    constructor(props) {
        super(props);

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleCourseTypesChange = this.handleCourseTypesChange.bind(this);
        this.handleDistanceChange = this.handleDistanceChange.bind(this);
    }

    handleCountryChange(country)
    {
        let modified = this.props.filters.withSelectedCountry(country);
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    handleCourseTypesChange(filter)
    {
        let modified = this.props.filters.withCourseTypes(filter);
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    handleDistanceChange(event, newValue)
    {
        let modified = this.props.filters.withDistance(newValue);
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    render() {
        return (
            <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                <Grid item>
                    <SplitButton
                        options={this.props.countries}
                        selectedOption={this.props.filters.location.countryIso2}
                        onSelectedOptionChange={this.handleCountryChange}
                    />
                </Grid>
                <Grid item>
                    <Slider
                        style={{width:"100px"}}
                        defaultValue={this.props.filters.distance}
                        valueLabelDisplay="off"
                        step={5}
                        min={10}
                        max={200}
                        onChange={this.handleDistanceChange}
                    />
                </Grid>
                <Grid item>
                    <CourseTypeFilter
                        label=""
                        enforceAnySelectedMode={true}
                        allowUnspecifiedMode={false}
                        courseTypes={this.props.courseTypes}
                        filters={this.props.filters.courseTypes}
                        onFilterChange={this.handleCourseTypesChange}
                    />
                </Grid>
            </Grid>
        );
    }
}


export default CourseFilter;

CourseFilter.propTypes = {
    countries: PropTypes.array.isRequired,
    courseTypes: PropTypes.array.isRequired,
    filters: PropTypes.instanceOf(CourseFilters).isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

CourseFilter.defaultProps = {
    countries: [],
    courseTypes: [],
    filters: CourseFilters.empty(),
    onFilterChange: null,
};