import React from 'react'
import * as PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

function MultiOptionFilter(props) {
    const t = props.t;
    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCheckedChange = (event) =>
    {
        if (props.onSelectionChange != null)
        {
            let value = event.target.value;
            let selected = new Set(props.selectedValues);
            if (event.target.checked)
                selected.add(value);
            else
                selected.delete(value);
            props.onSelectionChange(selected);
        }
    };

    const handleClickClear = (_event) => {
        if (props.onSelectionChange != null)
            props.onSelectionChange(new Set());
    };

    const renderOptions = () => {
        return (
            <FormGroup row>
                {props.options.map((option, index) => {
                    const isSelected = props.selectedValues.has(option.value);

                    return (
                        <Tooltip key={index} title={option.tooltip}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isSelected}
                                        value={option.value}
                                        color="primary"
                                        onChange={handleCheckedChange}
                                    />
                                }
                                label={option.label}
                            />
                        </Tooltip>
                    )
                })}
            </FormGroup>
        );
    };

    const renderSelected = () => {
        return props.options
            .filter(o => props.selectedValues.has(o.value))
            .map((option, index) => {
                return (
                    <Tooltip key={index} title={option.tooltip}>
                        <b>{option.label}&nbsp;</b>
                    </Tooltip>
                );
            });
    };

    if (props.options.length <= 5 || props.disablePopup)
        return renderOptions();
    else
        return (
            <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        {
                            renderOptions()
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClickClear} color="primary">
                            {t('Clear Button')}
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            {t('Save Button')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <span onClick={handleClickOpen} style={{cursor: "grab"}}>
                    { props.selectedValues.size === 0 &&
                        <b>{t('None Selected')}</b>
                    }
                    { props.selectedValues.size > 0 &&
                        renderSelected()
                    }
                </span>
            </div>
        )
}

export default withTranslation("filters")(MultiOptionFilter);

MultiOptionFilter.propTypes = {
    disablePopup: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
    selectedValues: PropTypes.object.isRequired,
    onSelectionChange: PropTypes.func,
};

MultiOptionFilter.defaultProps = {
    disablePopup: false,
    options: [],
    selectedValues: new Set(),
    onSelectionChange: null,
};