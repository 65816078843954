import ValidationHelper from "./ValidationHelper";

export default class GridHelper {
    static textColumn(headerName, field) {
        return GridHelper.withTextFilter({
            headerName: headerName,
            field: field,
            sortable: true,
            resizable: true,
        });
    }

    static numberColumn(headerName, field) {
        return GridHelper.withNumberFilter({
            headerName: headerName,
            field: field,
            sortable: true,
            resizable: true,
        });
    }

    static boolColumn(headerName, field) {
        return GridHelper.withBoolFilter({
            headerName: headerName,
            field: field,
            sortable: true,
            resizable: true,
        });
    }

    static dateColumn(headerName, field) {
        return GridHelper.withDateFilter({
            headerName: headerName,
            field: field,
            sortable: true,
            resizable: true,
        });
    }

    static withCheckbox(columnDef) {
        columnDef.headerCheckboxSelection = true;
        columnDef.headerCheckboxSelectionFilteredOnly = true;
        columnDef.checkboxSelection = true;
        return columnDef;
    }

    static withTextFilter(columnDef) {
        columnDef.filter= 'agTextColumnFilter';
        columnDef.filterParams= {
            applyButton: true,
            resetButton: true,
            filterOptions: [
                'contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith',
                {
                    displayKey: 'isSpecified',
                    displayName: 'is Specified',
                    test: function(filterValue, cellValue) {
                        return !ValidationHelper.isEmpty(cellValue) ;
                    },
                    hideFilterInput: true
                },
                {
                    displayKey: 'isUnspecified',
                    displayName: 'is Unspecified',
                    test: function(filterValue, cellValue) {
                        return ValidationHelper.isEmpty(cellValue) ;
                    },
                    hideFilterInput: true
                },
            ]
        };
        return columnDef;
    }

    static withNumberFilter(columnDef) {
        columnDef.filter= 'agNumberColumnFilter';
        columnDef.filterParams= {
            applyButton: true,
            resetButton: true,
            filterOptions: [
                'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange',
                {
                    displayKey: 'isSpecified',
                    displayName: 'is Specified',
                    test: function(filterValue, cellValue) {
                        return cellValue != null;
                    },
                    hideFilterInput: true
                },
                {
                    displayKey: 'isUnspecified',
                    displayName: 'is Unspecified',
                    test: function(filterValue, cellValue) {
                        return cellValue == null;
                    },
                    hideFilterInput: true
                },
            ]
        };
        return columnDef;
    }

    static withBoolFilter(columnDef) {
        columnDef.filter= 'agNumberColumnFilter';
        columnDef.filterParams= {
            suppressAndOrCondition: true,
            applyButton: true,
            resetButton: true,
            filterOptions: [
                'empty',
                {
                    displayKey: 'isTrue',
                    displayName: 'is True',
                    test: function(filterValue, cellValue) {
                        return cellValue !== null && cellValue;
                    },
                    hideFilterInput: true
                },
                {
                    displayKey: 'isFalse',
                    displayName: 'is False',
                    test: function(filterValue, cellValue) {
                        return cellValue === null || !cellValue;
                    },
                    hideFilterInput: true
                },
            ],
        };
        return columnDef;
    }

    static withDateFilter(columnDef) {
        columnDef.valueFormatter = (params) => {
            if (!params.value)
                return null;
            return params.value.toLocaleDateString();
        };
        columnDef.filter= 'agDateColumnFilter';
        columnDef.filterParams= {
            applyButton: true,
            resetButton: true,
            filterOptions: [
                'equals', 'notEqual', 'lessThan', 'greaterThan', 'inRange',
                {
                    displayKey: 'isSpecified',
                    displayName: 'is Specified',
                    test: function(filterValue, cellValue) {
                        return cellValue != null;
                    },
                    hideFilterInput: true
                },
                {
                    displayKey: 'isUnspecified',
                    displayName: 'is Unspecified',
                    test: function(filterValue, cellValue) {
                        return cellValue == null;
                    },
                    hideFilterInput: true
                },
            ]
        };
        return columnDef;
    }
}