import {Circle, RegularShape, Fill, Stroke, Style, Text} from 'ol/style';

class MarkerStyleFactory {
    constructor() {
        this.defaultCache = [];
        this.selectedCache = [];
        this.highlightedCache = [];
    }

    static createStyleId(type, params) {
        return {
            type: type,
            params: params,
        };
    }

    withStyleCache(cache, styleId, constructor) {
        let key = styleId.type + ":" + styleId.params.join(":");
        let style = cache[key];
        if (!style) {
            style = constructor();
            cache[key] = style;
        }
        return style;
    }

    createMarker(styleId, strokeColor, strokeWidth) {
        let style = {};
        switch (styleId.type) {
            // course marker
            case "c":
                let bgColor = styleId.params[0];
                let count = styleId.params[1];
                style = this.createSquareMarker(15, bgColor, strokeColor, strokeWidth, count.toString());
                break;
            // registration marker
            case "r":
                let regCount = styleId.params[0];
                let r = regCount > 1 ? 10 : 5;
                let text = regCount > 1 ? regCount.toString() : null;
                let color = regCount > 1 ? "rgba(212,87,69,0.8)" : "rgba(212,87,69,0.6)";
                style = this.createCircleMarker(r, color, strokeColor, strokeWidth, text);
                break;
            // cluster marker
            case "g":
                let markCount = styleId.params[0];
                style = [
                    this.createCircleMarker(20, "rgba(83,152,199,0.2)", "rgba(83,152,199,0.8)", strokeWidth, null),
                    this.createCircleMarker(12, "rgb(83,152,199)", strokeColor, strokeWidth, markCount.toString()),
                ];
                break;
            // unknown/missing
            default:
                style = this.createSquareMarker(10, "#fff", strokeColor, strokeWidth, "err");
                break;
        }

        return style;
    }


    createDefault(styleId) {
        return this.createMarker(styleId, "#fff", 1);
    }

    createSelected(styleId) {
        return this.createMarker(styleId, "rgb(66,82,175)", 2);
    }

    createHighlighted(styleId) {
        return this.createMarker(styleId, "rgb(66,82,175)", 2);
    }

    getStyleId(feature) {
        // markers
        let styleId = feature.get("styleId");
        if (styleId != null)
            return styleId;

        // clusters
        let features = feature.get("features");
        if (features != null)
        {
            let usersCount = features
                .map(f => f.get("users").size)
                .reduce((a, b) => a + b, 0);

            return MarkerStyleFactory.createStyleId("g", [usersCount]);
        }


        return MarkerStyleFactory.createStyleId("err", null);
    }

    getDefault(feature) {
        let styleId = this.getStyleId(feature);
        return this.withStyleCache(this.defaultCache, styleId,
            () => {
                return this.createDefault(styleId);
            });
    }

    getSelected(feature) {
        let styleId = this.getStyleId(feature);
        return this.withStyleCache(this.selectedCache, styleId,
            () => {
                return this.createSelected(styleId);
            });
    }

    getHighlighted(feature)
    {
        let styleId = this.getStyleId(feature);
        return this.withStyleCache(this.highlightedCache, styleId,
            () => {
                return this.createHighlighted(styleId);
            });
    }

    createCircleMarker(radius, bgColor, fgColor, width, text) {
        return new Style({
            image: new Circle({
                radius: radius,
                stroke: new Stroke({
                    color: fgColor,
                    width: width
                }),
                fill: new Fill({
                    color: bgColor
                })
            }),
            text: text != null
                ? new Text({
                    text: text,
                    fill: new Fill({
                        color: fgColor
                    })
                }) : null
        });
    }

    createSquareMarker(radius, bgColor, fgColor, width, text) {
        return new Style({
            image: new RegularShape({
                points: 4,
                angle: Math.PI / 4,
                radius: radius,
                stroke: new Stroke({
                    color: fgColor,
                    width: width,
                }),
                fill: new Fill({
                    color: bgColor
                })
            }),
            text: text != null
                ? new Text({
                    text: text,
                    fill: new Fill({
                        color: fgColor
                    })
                }) : null
        });
    }
}

export default MarkerStyleFactory;