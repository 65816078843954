import React from 'react'
import {withTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import COURSESINFO from "../../resources/pl/courses-info.md"

class DisplayMarkdownPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            markdown: "# this is markdown"
        };
    }

    componentDidMount() {
        fetch(COURSESINFO)
        .then(res => {
            if (res.status === 200) {
                res.text()
                .then(data => {
                    this.setState((_prevState, _props) => {
                        return {
                            markdown: data,
                        }
                    });
                });
            }
        });
    }

    render() {
        return (
            <main>
                <ReactMarkdown source={this.state.markdown} />
            </main>
        );
    }
}

export default withTranslation()(DisplayMarkdownPage);