import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend)          // load translation using xhr -> see /public/locales: https://github.com/i18next/i18next-xhr-backend
    .use(LanguageDetector) // detect user language: https://github.com/i18next/i18next-browser-languageDetector
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({        // init i18next: https://www.i18next.com/overview/configuration-options
        fallbackLng: 'en',
        debug: true,
        load: 'languageOnly',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    })
    .then(_t => {
        i18n.loadNamespaces('validation')
            .catch(err => window.alert("i18next: " + err));
    })
    .catch(err => window.alert("i18next: " + err));

export default i18n;