import CollectionHelper from "../helpers/CollectionHelper";

export class SelectionModes {
    static Unspecified = "unspecified";
    static None = "none";
    static Any = "any";
    static AllSelected = "allSelected";
    static AnySelected = "anySelected";
}

export class CourseTypeFilters {
    constructor(rev, mode, selected)
    {
        this.rev = rev;
        this.mode = mode;
        this.selected = selected;
    }

    static unspecified() {
        return new CourseTypeFilters(0, SelectionModes.Unspecified, new Set());
    }

    toLogString() {
        return `[course types filter] #${this.rev}, mode: ${this.mode}, selected: [${Array.from(this.selected).join(',')}]`;
    }

    toApiModel() {
        return {
            mode: this.mode,
            selected: this.isInSelectedMode() ? Array.from(this.selected) : null
        }
    }

    withMode(mode) {
        if (this.mode !== mode)
            return new CourseTypeFilters(this.rev + 1, mode, new Set());
        else
            return this;
    }

    withAllSelected(selected) {
        return this._withSelected(SelectionModes.AllSelected, selected);
    }

    withAnySelected(selected) {
        return this._withSelected(SelectionModes.AnySelected, selected);
    }

    _withSelected(mode, selected) {
        if (this.mode !== mode ||
            !CollectionHelper.setEquals(this.selected, selected))
            return new CourseTypeFilters(this.rev + 1, mode, selected);
        else
            return this;
    }

    isSelected(type) {
        if (this.mode === SelectionModes.Any)
            return true;
        if (this.mode === SelectionModes.AnySelected)
            return this.selected.has(type);
        return false;
    }

    isInSelectedMode() {
        return this.mode === SelectionModes.AllSelected || this.mode === SelectionModes.AnySelected
    }

    clearSelectedIfSelected() {
        if (this.isInSelectedMode())
            return this._withSelected(this.mode, new Set());
        else
            return this;
    }

    isValid() {
        return !(this.isInSelectedMode() && this.selected.size === 0);
    }
}