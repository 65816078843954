import React from 'react'
import MapView from './ui/MapView'
import * as PropTypes from "prop-types";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import {Vector as VectorLayer} from 'ol/layer';
import {Cluster, Vector as VectorSource} from 'ol/source';
import {fromLonLat} from 'ol/proj'
import Select from 'ol/interaction/Select';
import CourseFilters from "../models/CourseFilters";
import MarkerStyleFactory from "../helpers/MarkerStyleFactory";
import CollectionHelper from "../helpers/CollectionHelper";

class CourseRegistrationsMap extends React.Component {
    constructor(props) {
        super(props);

        this.markerIndex = 0;
        this.markerStyles = new MarkerStyleFactory();

        this.getMarkerIndex = this.getMarkerIndex.bind(this);
        this.updateSelectionState = this.updateSelectionState.bind(this);
        this.handleMapInitialized = this.handleMapInitialized.bind(this);
        this.defaultMarkerStyle = this.defaultMarkerStyle.bind(this);
        this.selectedMarkerStyle = this.selectedMarkerStyle.bind(this);
        this.highlightedMarkerStyle = this.highlightedMarkerStyle.bind(this);

        this.state = {
            selectedMarkerIds: new Set(),
        };
    }

    getMarkerIndex() {
        return this.markerIndex++;
    }

    updateSelectionState(toDeselect, toSelect) {
        if (toDeselect.size > 0 || toSelect.size > 0)
            console.log("[map] updating selection state -" + toDeselect.size + " +"+ toSelect.size);

        let fire = false;
        let userIds = new Set();
        let markers = this.registrationMarks;

        this.setState((state, props) => {
            let selected = new Set(state.selectedMarkerIds);
            toDeselect.forEach(id => selected.delete(id));
            toSelect.forEach(id => selected.add(id));

            if (markers != null) {
                userIds = new Set(markers
                    .filter(m => selected.has(m.getId()))
                    .flatMap(m => {
                        let users = m.get("users");
                        return users != null
                            ? Array.from(users)
                            : null;
                    })
                    .filter(i => i != null));

                fire = !CollectionHelper.setEquals(userIds, props.participants);
            }

            return {
                selectedMarkerIds: selected
            };
        });

        if (fire)
            this.props.onParticipantsChange(userIds);
    };

    createCourseMarkers() {
        let getIdx = this.getMarkerIndex;

        return this.props.courses
            .filter(c => c.location != null)
            .map(function (course) {
                let coordinates = fromLonLat([
                    course.location.lng,
                    course.location.lat]);

                let feature = new Feature(new Point(coordinates));
                feature.setId(getIdx());
                feature.set("styleId", MarkerStyleFactory.createStyleId("c", [course.color, course.registrationsCount]));
                feature.set("courseId", course.id);
                return feature;
            });
    }

    createRegistrationMarkers() {
        let getIdx = this.getMarkerIndex;
        let filters = this.props.filters;

        return this.props.registrations
            .flatMap(function (typeRegs) {
                if (typeRegs.sources != null) {
                    return typeRegs.sources
                        .map(function (src) {
                            if (src.registrations != null)
                            {
                                let selectedRegistrations = src.registrations
                                    .filter(r => filters.selectedCourses.has(r.courseId));
                                if (selectedRegistrations.length > 0)
                                {
                                    let coordinates = fromLonLat([
                                        src.location.lng,
                                        src.location.lat]);

                                    let userIds = new Set(selectedRegistrations.map(r => r.userId));

                                    let feature = new Feature(new Point(coordinates));
                                    feature.setId(getIdx());
                                    feature.set("styleId", MarkerStyleFactory.createStyleId("r", [userIds.size]));
                                    feature.set("users", userIds);
                                    feature.set("registrations", selectedRegistrations);
                                    return feature;
                                }
                            }
                            return null;
                        });
                }
                else
                    return null;
            })
            .filter(el => el != null);
    }

    defaultMarkerStyle(feature) { return this.markerStyles.getDefault(feature); }
    selectedMarkerStyle(feature) { return this.markerStyles.getSelected(feature); }
    highlightedMarkerStyle(feature) { return this.markerStyles.getHighlighted(feature); }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.olMap == null) {
            console.log("[map] OL infrastructure not ready");
            return;
        }

        let defaultStyleFunc = this.defaultMarkerStyle;
        let selectedStyleFunc = this.selectedMarkerStyle;
        let highlightStyleFunc = this.highlightedMarkerStyle;

        // generate markers only when registration data change
        if (prevProps.registrations !== this.props.registrations ||
            prevProps.filters.distance !== this.props.filters.distance) {
            console.log("[map] updating markers");
            this.cleanupMap();

            this.registrationMarks = this.createRegistrationMarkers();
            if (this.registrationMarks.length > 0) {
                let markersSource = new VectorSource({
                    features: this.registrationMarks,
                });

                let clustersSource = new Cluster({
                    distance: this.props.filters.distance,
                    source: markersSource
                });

                this.markers = new VectorLayer({
                    source: markersSource,
                    style: defaultStyleFunc,
                });
                this.olMap.addLayer(this.markers);

                if (this.props.filters.courseTypes.selected.size === 1 ||
                    new Set(this.props.courses.map(c => c.code)).size === 1) {
                    this.clusters = new VectorLayer({
                        source: clustersSource,
                        style: defaultStyleFunc,
                    });
                    this.olMap.addLayer(this.clusters);
                }
            }

            let courses = this.createCourseMarkers();
            if (courses.length > 0) {
                let markersSource = new VectorSource({
                    features: courses
                });

                this.courseMarkers = new VectorLayer({
                    source: markersSource,
                    style: defaultStyleFunc,
                });

                this.olMap.addLayer(this.courseMarkers);
            }
        }

        // update marker styles based on selection
        if (this.registrationMarks != null &&
            !CollectionHelper.setEquals(prevState.selectedMarkerIds, this.state.selectedMarkerIds)) {
            console.log("[map] updating marker styles, selected: "+this.state.selectedMarkerIds.size);

            let selectedIds = this.state.selectedMarkerIds;
            this.registrationMarks.forEach(marker => {
                let style = selectedIds.has(marker.getId())
                    ? highlightStyleFunc
                    : null;
                marker.setStyle(style);
            });

            // try to re-select clusters which contain selected markers (OL re-calculates clusters when style on features changes)
            if (this.clusters != null) {
                this.clusters.getSource().getFeatures().forEach(f => {
                    let cluster = f.get("features");
                    if (cluster != null &&
                        cluster.find(marker => selectedIds.has(marker.getId())) != null) {
                        f.setStyle(selectedStyleFunc);
                    }
                });
            }
        }

        // enable/disable clusters when course marker is selected
        if (this.clusters != null) {
            let selectedFeatures = this.select.getFeatures().getArray();
            let selectedCourseOrNull = selectedFeatures
                .find(f => f.get("courseId") != null);
            this.clusters.setVisible(selectedCourseOrNull == null);
        }
    }

    handleMapInitialized(map) {
        this.olMap = map;

        const selectedStyleFunc = this.selectedMarkerStyle;
        const updateSelectionState = this.updateSelectionState;

        const addMarkerIdsForCourseId = (set, courseId) => {
            this.registrationMarks.forEach((marker) => {
                let registrations = marker.get("registrations");
                if (registrations != null &&
                    registrations.find(r => r.courseId === courseId) != null)
                    set.add(marker.getId());
            });
        };

        this.select = new Select({
            style: selectedStyleFunc,
            filter: (feature) => {
                return feature.get("courseId") != null ||
                    feature.get("features") != null;
            }
        });

        this.olMap.addInteraction(this.select);

        this.select.on('select', function(e) {
            let toDeselect = new Set();
            let toSelect = new Set();

            e.deselected.forEach(deselected => {
                let cluster = deselected.get("features");
                if (cluster != null)
                    cluster.forEach(f => toDeselect.add(f.getId()));

                let courseId = deselected.get("courseId");
                if (courseId != null)
                    addMarkerIdsForCourseId(toDeselect, courseId);
            });


            e.selected.forEach(selected => {
                let cluster = selected.get("features");
                if (cluster != null)
                    cluster.forEach(f => toSelect.add(f.getId()));

                let courseId = selected.get("courseId");
                if (courseId != null)
                    addMarkerIdsForCourseId(toSelect, courseId);
            });

            updateSelectionState(toDeselect, toSelect);
        });
    }

    cleanupMap() {
        this.markerIndex = 0;
        if (this.registrationMarks != null)
            this.registrationMarks = null;
        if (this.markers != null) {
            this.olMap.removeLayer(this.markers);
            this.markers = null;
        }
        if (this.clusters != null) {
            this.olMap.removeLayer(this.clusters);
            this.clusters = null;
        }
        if (this.courseMarkers != null) {
            this.olMap.removeLayer(this.courseMarkers);
            this.courseMarkers = null;
        }
        if (this.select != null)
            this.select.getFeatures().clear();

        this.updateSelectionState(this.state.selectedMarkerIds, new Set());
    }

    render() {
        return (
            <MapView
                center={this.props.center}
                zoom={this.props.zoom}
                onMapInitialized={this.handleMapInitialized}
                knownHeight={this.props.knownHeight}
            />
        );
    }
}

export default CourseRegistrationsMap;

CourseRegistrationsMap.propTypes = {
    zoom: PropTypes.number.isRequired,
    center: PropTypes.object.isRequired,
    filters: PropTypes.instanceOf(CourseFilters).isRequired,
    courses: PropTypes.array.isRequired,
    registrations: PropTypes.array.isRequired,
    onParticipantsChange: PropTypes.func.isRequired,
    knownHeight: PropTypes.number,
};

CourseRegistrationsMap.defaultProps = {
    zoom: 2,
    center: {
        lat: 49.19493,
        lng: 16.61239
    },
    filters: CourseFilters.empty(),
    courses: [],
    registrations: [],
    onParticipantsChange: (_participants) => {},
    knownHeight: null
};