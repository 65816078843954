import React from 'react'
import {withTranslation} from 'react-i18next';
import SplitButton from "./ui/SplitButton";

class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);

        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    handleLanguageChange(language)
    {
        if (this.props.i18n.language !== language) {
            this.props.i18n.changeLanguage(language);
        }
    }

    render() {
        const languages = ["en", "sk", "pl", "cs"];


        return (
            <SplitButton
                options={languages}
                selectedOption={this.props.i18n.language}
                onSelectedOptionChange={this.handleLanguageChange}
            />
        );
    }
}


export default withTranslation()(LanguageSelector);