import {CourseTypeFilters} from "./CourseTypeFilters";
import {LocationFilters} from "./LocationFilters";
import CollectionHelper from "../helpers/CollectionHelper";

export class UserSelectionFilters {
    static TeachersWithSchool = "teachersWithSchool";
    static TeachersWithoutSchool = "teachersWithoutSchool";

    static allSelections = [
        UserSelectionFilters.TeachersWithSchool,
        UserSelectionFilters.TeachersWithoutSchool,
    ];
}

export class UserRoleFilters {
    static EnabledTrainers = "enabledTrainers";
    static DisabledTrainers = "disabledTrainers";
    static EnabledCoordinators = "enabledCoordinators";
    static DisabledCoordinators = "disabledCoordinators";

    static allRoles = [
        UserRoleFilters.EnabledTrainers,
        UserRoleFilters.DisabledTrainers,
        UserRoleFilters.EnabledCoordinators,
        UserRoleFilters.DisabledCoordinators,
    ];
}

export class UserFilters {
    constructor(rev, location, include, require, hasCompleted, hasNotCompleted, hasSignedFor, hasNotSignedFor)
    {
        this.rev = rev;
        this.location = location;
        this.include = include;
        this.require = require;
        this.hasCompleted = hasCompleted;
        this.hasNotCompleted = hasNotCompleted;
        this.hasSignedFor = hasSignedFor;
        this.hasNotSignedFor = hasNotSignedFor;
    }

    static empty() {
        return new UserFilters(0,
            LocationFilters.empty(),
            new Set(),
            new Set(),
            CourseTypeFilters.unspecified(),
            CourseTypeFilters.unspecified(),
            CourseTypeFilters.unspecified(),
            CourseTypeFilters.unspecified()
        );
    }

    toLogString() {
        return `[user filter] #${this.rev}, 
            location: ${this.location.toLogString()}, 
            include: [${Array.from(this.include).join(',')}],
            require: [${Array.from(this.require).join(',')}],  
            hasCompleted: ${this.hasCompleted.toLogString()},
            hasNotCompleted: ${this.hasNotCompleted.toLogString()},
            hasSignedFor: ${this.hasSignedFor.toLogString()},
            hasNotSignedFor: ${this.hasNotSignedFor.toLogString()}`;
    }

    toApiModel() {
        return {
            location: this.location.toApiModel(),
            include: Array.from(this.include),
            require: Array.from(this.require),
            hasCompleted: this.hasCompleted.toApiModel(),
            hasNotCompleted: this.hasNotCompleted.toApiModel(),
            hasSignedFor: this.hasSignedFor.toApiModel(),
            hasNotSignedFor: this.hasNotSignedFor.toApiModel()
        };
    }

    withLocation(location) {
        if (location !== this.location.rev)
            return new UserFilters(this.rev + 1,
                location,
                this.include,
                this.require,
                this.hasCompleted,
                this.hasNotCompleted,
                this.hasSignedFor,
                this.hasNotSignedFor
            );
        else
            return this;
    }

    withInclude(include) {
        if (!CollectionHelper.setEquals(include, this.include))
            return new UserFilters(this.rev + 1,
                this.location,
                include,
                this.require,
                this.hasCompleted,
                this.hasNotCompleted,
                this.hasSignedFor,
                this.hasNotSignedFor
            );
        else
            return this;
    }

    withRequire(require) {
        if (!CollectionHelper.setEquals(require, this.require))
            return new UserFilters(this.rev + 1,
                this.location,
                this.include,
                require,
                this.hasCompleted,
                this.hasNotCompleted,
                this.hasSignedFor,
                this.hasNotSignedFor
            );
        else
            return this;
    }

    withHasCompleted(hasCompleted) {
        if (hasCompleted.rev !== this.hasCompleted)
            return new UserFilters(this.rev + 1,
                this.location,
                this.include,
                this.require,
                hasCompleted,
                this.hasNotCompleted,
                this.hasSignedFor,
                this.hasNotSignedFor
            );
        else
            return this;
    }

    withHasNotCompleted(hasNotCompleted) {
        if (hasNotCompleted.rev !== this.hasNotCompleted.rev)
            return new UserFilters(this.rev + 1,
                this.location,
                this.include,
                this.require,
                this.hasCompleted,
                hasNotCompleted,
                this.hasSignedFor,
                this.hasNotSignedFor
            );
        else
            return this;
    }

    withHasSignedFor(hasSignedFor) {
        if (hasSignedFor.rev !== this.hasSignedFor.rev)
            return new UserFilters(this.rev + 1,
                this.location,
                this.include,
                this.require,
                this.hasCompleted,
                this.hasNotCompleted,
                hasSignedFor,
                this.hasNotSignedFor
            );
        else
            return this;
    }

    withHasNotSignedFor(hasNotSignedFor) {
        if (hasNotSignedFor.rev !== this.hasNotSignedFor.rev)
            return new UserFilters(this.rev + 1,
                this.location,
                this.include,
                this.require,
                this.hasCompleted,
                this.hasNotCompleted,
                this.hasSignedFor,
                hasNotSignedFor
            );
        else
            return this;
    }

    clearActionSelection() {
        if (this.hasCompleted.isInSelectedMode() ||
            this.hasNotCompleted.isInSelectedMode() ||
            this.hasSignedFor.isInSelectedMode() ||
            this.hasNotSignedFor.isInSelectedMode())
            return new UserFilters(this.rev + 1,
                this.location,
                this.include,
                this.require,
                this.hasCompleted.clearSelectedIfSelected(),
                this.hasNotCompleted.clearSelectedIfSelected(),
                this.hasSignedFor.clearSelectedIfSelected(),
                this.hasNotSignedFor.clearSelectedIfSelected()
            );
        else
            return this;
    }

    isValid() {
        return this.include.size > 0 &&
            this.hasCompleted.isValid() &&
            this.hasNotCompleted.isValid() &&
            this.hasSignedFor.isValid() &&
            this.hasNotSignedFor.isValid();
    }
}