import React from 'react'
import {Link} from 'react-router-dom'

class MainPage extends React.Component {
    render() {
        return (
            <main>
                <h1>Welcome</h1>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/login">Login</Link></li>
                    <li><Link to="/courses">Courses</Link></li>
                    <li><Link to="/registrations">Registrations</Link></li>
                    <li><Link to="/emails">Emails</Link></li>
                </ul>

                <h2>Tests</h2>
                <ul>
                    <li><Link to="/test">Test Page</Link></li>
                    <li><Link to="/displayHtml">Html Info</Link></li>
                    <li><Link to="/displayMarkdown">Markdown Info</Link></li>
                </ul>
            </main>
        );
    }
}

export default MainPage;