import React from 'react'
import {withTranslation} from 'react-i18next';
import SendEmailDialog from "../../components/SendEmailDialog";


class TestPage extends React.Component {
    render() {
        const recipients = [
            {
                email: 'marekzizi@gmail.com',
                id: null,
                fullname: 'marek'
            }
        ];

        return (
            <main>
                <SendEmailDialog recipients={recipients}/>
            </main>
        );
    }
}

export default withTranslation()(TestPage);