import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from "../helpers/AuthenticationService";

const AuthenticatedRoute = ({ component: Component, roles, ...rest }) => (
    <AuthContext.Consumer>
    { value => {
        const auth = value.auth();
        const user = auth.user;

        return (
            <Route {...rest} render={props => {
                // not logged in so redirect to login page with the return url
                if (!auth.isAuthenticated) {
                    console.log("[auth] redirect to login (not logged)");
                    return (
                        <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
                    );
                }

                // check if route is restricted by role, not authorised role redirects to home page
                if (roles && user && user.roles &&
                    roles.filter(r => user.roles.indexOf(r) === -1).length > 0) {

                    console.log("[auth] redirect to main (not authorized)");
                    return (
                        <Redirect to={{pathname: '/'}}/>
                    );
                }

                // authorised so return component
                return (
                    <Component {...props} />
                );
            }}/>
        );
    }}
    </AuthContext.Consumer>
);

export default AuthenticatedRoute;