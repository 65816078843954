import React from 'react'
import * as PropTypes from 'prop-types'
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

function SingleOptionFilter(props) {
    const handleChange = (event) =>
    {
        if (props.onSelectionChange != null)
        {
            let value = event.target.value;
            if (value !== props.selectedValue)
                props.onSelectionChange(value);
        }
    };

    return (
        <Select
            id="single-option-select"
            value={props.selectedValue}
            onChange={handleChange}
        >
            { props.options.map((option, index) => {
                return (
                    <MenuItem key={index} value={option.value}>
                        {option.label}
                    </MenuItem>
                );
            })}
        </Select>
    );
}

export default SingleOptionFilter;

SingleOptionFilter.propTypes = {
    options: PropTypes.array.isRequired,
    selectedValue: PropTypes.string.isRequired,
    onSelectionChange: PropTypes.func,
};

SingleOptionFilter.defaultProps = {
    options: [],
    selectedValue: null,
    onSelectionChange: null,
};