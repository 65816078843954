import CollectionHelper from "../helpers/CollectionHelper";
import {LocationFilters} from "./LocationFilters";
import {CourseTypeFilters} from "./CourseTypeFilters";

class CourseFilters {
    constructor(
        rev,
        location,
        courseTypes,
        selectedCourses,
        distance)
    {
        this.rev = rev;
        this.location = location;
        this.courseTypes = courseTypes;
        this.selectedCourses = selectedCourses;
        this.distance = distance;
    }

    static empty() {
        return new CourseFilters(
            0,
            LocationFilters.empty(),
            CourseTypeFilters.unspecified().withAnySelected(new Set()),
            new Set(),
            50);
    }

    toLogString() {
        return `[filters] #${this.rev}, distance: ${this.distance},
            location: ${this.location.toLogString()},  
            types: ${this.courseTypes.toLogString()}, 
            courses: [${Array.from(this.selectedCourses).join(',')}]`;
    }

    toApiModel() {
        return {
            location: this.location.toApiModel(),
            courseTypes: this.courseTypes.toApiModel(),
            courses: Array.from(this.selectedCourses)
        }
    }

    withLocation(location) {
        if (this.location.rev !== location.rev)
            return new CourseFilters(
                this.rev + 1,
                location,
                this.courseTypes.withAnySelected(new Set()),
                new Set(),
                this.distance);
        else
            return this;
    }

    withSelectedCountry(country) {
        return this.withLocation(
            this.location.withCountry(country));
    }

    withCourseTypes(courseTypes) {
        if (courseTypes.rev !== this.courseTypes.rev)
            return new CourseFilters(
                this.rev + 1,
                this.location,
                courseTypes,
                this.selectedCourses,
                this.distance);
        else
            return this;
    }

    withSelectedTypes(selectedTypes) {
        return this.withCourseTypes(
            this.courseTypes.withAnySelected(selectedTypes));
    }

    withSelectedCourses(selectedCourses) {
        if (!CollectionHelper.setEquals(this.selectedCourses, selectedCourses))
            return new CourseFilters(
                this.rev + 1,
                this.location,
                this.courseTypes,
                selectedCourses,
                this.distance);
        else
            return this;
    }

    preselectCourseTypes(availableCourseTypes) {
        if (this.location.countryIso2 == null) {
            return this.withSelectedTypes(new Set());
        }
        else {
            let codes = availableCourseTypes
                .find(el => el.country === this.location.countryIso2)
                .visible;
            return this.withSelectedTypes(new Set(codes));
        }
    }

    preselectCourses(registrations) {
        if (this.courseTypes.selected.size === 0) {
            return this.selectedCourses.size === 0
                ? this
                : this.withSelectedCourses(new Set());
        }
        else {
            let ids = registrations
                .flatMap(r => r.courses)
                .filter(c => c != null && this.courseTypes.isSelected(c.code))
                .map(c => c.id);
            return this.withSelectedCourses(new Set(ids));
        }
    }

    withDistance(distance) {
        if (this.distance !== distance)
            return new CourseFilters(
                this.rev + 1,
                this.location,
                this.courseTypes,
                this.selectedCourses,
                distance);
        else
            return this;
    }

    filterRegistrationsBySelectedType(registrations) {
        if (registrations == null ||
            registrations.length === 0 ||
            this.courseTypes.selected.size === 0)
            return [];

        return registrations
            .filter(el => this.courseTypes.isSelected(el.code));
    }

    filterCoursesBySelectedType(registrations) {
        if (registrations.length === 0 ||
            this.courseTypes.selected.size === 0)
            return [];

        return registrations
            .filter(r => this.courseTypes.isSelected(r.code))
            .flatMap(r => r.courses)
            .filter(c => c != null);
    };

    filterCoursesBySelectedIds(coursesByType) {
        if (coursesByType == null ||
            coursesByType.length === 0 ||
            this.selectedCourses.size === 0)
            return [];

        return coursesByType
            .filter(c => this.selectedCourses.has(c.id));
    }
}

export default CourseFilters;