class ValidationHelper {
    constructor(t) {
        this.t = t;
    }

    static isEmpty(value) {
        return value == null || value.match(/^ *$/) !== null;
    }

    static isNumber(value) {
        return !this.isEmpty(value) && !isNaN(value);
    }

    static isEmail(value) {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
    }

    isEmpty(value) {
        return ValidationHelper.isEmpty(value);
    }

    isNumber(value) {
        return ValidationHelper.isNumber(value);
    }

    isEmail(value) {
        return ValidationHelper.isEmail(value);
    }

    required(value, emptyStr) {
        if (emptyStr && value === emptyStr)
            return [false, this.t('validation:Required field')];

        return this.isEmpty(value)
            ? [false, this.t('validation:Required field')]
            : [true, null];
    }

    optionalEmail(value) {
        if (this.isEmpty(value) ||
            this.isEmail(value))
            return [true, null];
        else
            return [false, this.t('validation:Invalid email address')];
    }

    optionalEmailList(value) {
        if (this.isEmpty(value))
            return [true, null];

        if (value.split(";").find(str => !this.isEmail(str)))
            return [false, this.t('validation:Invalid email address list')];
        else
            return [true, null];
    }

    requiredEmail(value) {
        return this.isEmpty(value)
            ? [false, this.t('validation:Required field')]
            : this.optionalEmail(value);
    }

    optionalEmailOrSid(value) {
        if (this.isEmpty(value) ||
            this.isNumber(value) ||
            this.isEmail(value))
            return [true, null];
        else
            return [false, this.t('validation:Invalid email address or SID.')];
    }

    requiredEmailOrSid(value) {
        return this.isEmpty(value)
            ? [false, this.t('validation:Required field')]
            : this.optionalEmailOrSid(value);
    }

    requiredEmailOrIdList(value) {
        const result = value
            .split('\n')
            .map(str => str.trim())
            .filter(str => this.isEmail(str) || this.isNumber(str));

        if (result.length === 0)
            return [false, this.t('validation:Invalid Emails or IDs')];
        else
            return [true, result];
    }
}

export default ValidationHelper;