class DataCache {
    constructor(keys, data) {
        this.keys = keys;
        this.data = data;
    }

    static empty() {
        return new DataCache(new Set(), []);
    }

    fetchForKeys(keys, fetchFunc, keySelectorFunc) {
        let result = [];
        let query = new Set();

        keys.forEach(k => {
            if (this.keys.has(k))
                result.push(this.data[k]);
            else
                query.add(k);
        });

        if (query.size === 0)
            return new Promise((resolve, _reject) => resolve(result));
        else
            return fetchFunc(query)
                .then(data => {
                    data.forEach(i => {
                        let key = keySelectorFunc(i);
                        this.keys.add(key);
                        this.data[key] = i;
                    });
                    return result.concat(data);
                });
    }
}

export default DataCache;