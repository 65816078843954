import React, {Suspense} from 'react';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AuthContext, AuthenticationService, Role } from './helpers/AuthenticationService'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import LoginAvatar from './components/LoginAvatar'
import MainMenu from './components/MainMenu'
import LanguageSelector from "./components/LanguageSelector";

// pages
import CoursesPage from "./pages/CoursesPage";
import RegistrationsPage from "./pages/RegistrationsPage";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import EmailsPage from "./pages/EmailsPage";

// test pages
import TestPage from "./pages/tests/TestPage";
import DisplayHtmlPage from "./pages/tests/DisplayHtml";
import DisplayMarkdownPage from "./pages/tests/DisplayMarkdown";

const authService = new AuthenticationService();

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            auth: authService.loggedOut,
        };

        this.authTimer = null;
        this.getAuth = this.getAuth.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    getAuth() {
        const fromCookie = authService.init();

        if (fromCookie.isAuthenticated !== this.state.auth.isAuthenticated)
            console.log('[auth] getting Cookie: '+fromCookie.isAuthenticated+' State: '+this.state.auth.isAuthenticated);

        if (!fromCookie.isAuthenticated && this.state.auth.isAuthenticated)
        {
            const app = this;
            this.authTimer = setTimeout(() => app.setAuth(fromCookie), 100);
            return fromCookie;
        }
        else
            return this.state.auth;
    }

    componentDidMount() {
        console.log('[auth] init');
        this.setAuth(authService.init());
    }

    componentWillUnmount() {
        if (this.authTimer) {
            clearTimeout(this.authTimer);
            this.authTimer = null;
        }
    }

    setAuth(data) {
        console.log('[auth] setting State: '+data.isAuthenticated);
        this.setState((_state, _props) => {
            return {
                auth: data,
            }
        });
        return data;
    }

    handleLogin(login, password, rememberMe) {
        return authService.login(login, password, rememberMe)
            .then(res => {
                return this.setAuth(res);
            })
            .catch(err => {
                console.log(err);
                this.setAuth(authService.loggedOut);
                return Promise.reject(err);
            });
    }

    handleLogout() {
        return authService.logout()
            .then(res => {
                return this.setAuth(res);
            })
            .catch(err => {
                console.log(err);
                this.setAuth(authService.loggedOut);
                return Promise.reject(err);
            });
    }

    render() {
        const ctx = {
            auth: this.getAuth,
            login: this.handleLogin,
            logout: this.handleLogout
        };

        return (
            <Suspense fallback="loading...">
                <div className="App">
                    <AuthContext.Provider value={ctx}>
                        <Router>
                            <div className="App-header">
                                <AppBar>
                                    <Toolbar >
                                        <MainMenu/>
                                        <Link
                                            to="/"
                                            style={{color: "white", textDecoration: "none", flexGrow: 1}}
                                        >
                                            SGP-Systems
                                        </Link>

                                        <LoginAvatar />
                                        <div style={{paddingLeft: "10px"}}>
                                            <LanguageSelector />
                                        </div>
                                    </Toolbar>
                                </AppBar>
                            </div>
                            <div className="App-content">
                                <Switch>
                                    <AuthenticatedRoute path="/courses" roles={[Role.Administrator]} component={CoursesPage}/>
                                    <AuthenticatedRoute path="/registrations" roles={[Role.Administrator]} component={RegistrationsPage}/>
                                    <AuthenticatedRoute path="/emails" roles={[Role.Administrator]} component={EmailsPage}/>
                                    <Route path="/login" component={LoginPage}/>
                                    <Route path="/test" component={TestPage}/>
                                    <Route path="/displayHtml" component={DisplayHtmlPage}/>
                                    <Route path="/displayMarkdown" component={DisplayMarkdownPage}/>
                                    <Route exact path="/" component={MainPage}/>
                                </Switch>
                            </div>
                            <div className="App-footer">
                                Copyright 2019
                            </div>
                        </Router>
                    </AuthContext.Provider>
                </div>
            </Suspense>
        );
    }
}

export default App;
