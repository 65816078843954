import React from 'react';
import * as PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import {Formik, Field} from "formik";
import ValidationHelper from "../helpers/ValidationHelper";
import {AuthContext} from "../helpers/AuthenticationService";
import {Link} from "react-router-dom";
import FetchHelper from "../helpers/FetchHelper";
import TextEditor from "./TextEditor";

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    formField: {
        width: '400px'
    },
    formFieldWide: {
        width: '600px'
    },
    hint: {
        color: '#606060',
        fontSize: 'small',
        fontWeight: 'bold'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SendEmailDialog(props) {
    const t = props.t;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const validator = new ValidationHelper(t);

    const handleValidate = (values) => {
        let errors = {};

        let [valid, msg] = validator.required(values.subject);
        if (!valid)
            errors.subject = msg;

        if (values.forceHtmlRaw) {
            [valid, msg] = validator.required(values.htmlRaw);
            if (!valid)
                errors.htmlRaw = msg;
        } else {
            [valid, msg] = validator.required(values.body, '<p><br></p>');
            if (!valid)
                errors.body = msg;
        }

        [valid, msg] = validator.optionalEmailList(values.copyTo);
        if (!valid)
            errors.copyTo = msg;

        return errors;
    };

    const copyMacrosToClipboard = (_event) => {
        window.navigator.clipboard.writeText("{ID}\n{EMAIL}\n{FULLNAME}");
    };

    const sendEmail = (sender, subject, htmlBody, htmlRaw, copyTo, copyToSender) => {
        const email = {
            sender: sender,
            subject: subject,
            htmlBody: htmlBody,
            htmlRaw: htmlRaw,
            copyTo: copyTo.split(';')
                .filter(email => !validator.isEmpty(email)),
            copyToSender: copyToSender
        };

        let url = null;
        let recipients = null;
        if (props.recipientUserIds.length > 0) {
            url = "/api/messaging/sendEmailToUserIds";
            recipients = props.recipientUserIds;
        }
        else if (props.recipients.length > 0) {
            url = "/api/messaging/sendEmailToAny";
            recipients = props.recipients;
        }

        if (url == null || recipients == null)
            return Promise.reject(FetchHelper.badRequestError("No recipients"));

        const body = JSON.stringify({
            recipients: recipients,
            email: email
        });

        const request = {
            method: 'POST',
            body: body,
        };

        return FetchHelper.fetchJsonApi(url, request)
            .then(res => {
                console.log(res);
                return res.data;
            })
            .catch(err => {
                console.log(err);
                return Promise.reject(err);
            });
    };

    return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpen} disabled={props.disabled}>
                    {t('Send Email Button')}
                </Button>
                <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {t('Compose New Email')}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Paper style={{padding: "15px", margin: "10px"}}>
                        <AuthContext.Consumer>
                        { value => {
                            const auth = value.auth();
                            if (auth.isAuthenticated) {
                                const senderId = auth.user.userId;
                                const sender = auth.user.fullname;

                                return (
                                    <Formik
                                        initialValues={{
                                            from: senderId +" - "+sender,
                                            subject: '',
                                            body: '',
                                            forceHtmlRaw: false,
                                            htmlRaw: '',
                                            copyTo: '',
                                            copyToSender: true,
                                        }}
                                        onSubmit={(values, {setSubmitting}) => {
                                            setSubmitting(true);

                                            let htmlBodyArg = null;
                                            let htmlRawArg = null;
                                            if (values.forceHtmlRaw) {
                                                htmlRawArg = values.htmlRaw;
                                            }
                                            else {
                                                htmlBodyArg = values.body;
                                            }

                                            sendEmail(senderId, values.subject, htmlBodyArg, htmlRawArg, values.copyTo, values.copyToSender)
                                                .then(job => {
                                                    setSubmitting(false);
                                                    window.alert(t('Send Email Completed', { count: job.scheduled, jobId: job.jobId }));
                                                    handleClose();
                                                })
                                                .catch(err => {
                                                    setSubmitting(false);
                                                    window.alert(t('Send Email Failed', { err: err.message }));
                                                });
                                        }}
                                        validate={handleValidate}
                                    >
                                        {props => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                isSubmitting,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                            } = props;
                                            return (
                                                <form onSubmit={handleSubmit} autoComplete="off">
                                                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                                                        <Grid item>
                                                            <Grid container direction="row" wrap="nowrap" spacing={1}>
                                                                <Grid item>
                                                                    <Tooltip title={t('Copy Macros to Clipboard')}>
                                                                        <span className="hint" style={{cursor: "grab"}} onClick={copyMacrosToClipboard}>
                                                                            {t('Available Macros')}:
                                                                        </span>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title={t('Hint ID')}>
                                                                        <span className={classes.hint}>{'{'}ID{'}'}</span>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title={t('Hint Email')}>
                                                                        <span className={classes.hint}>{'{'}EMAIL{'}'}</span>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Tooltip title={t('Hint Fullname')}>
                                                                        <span className={classes.hint}>{'{'}FULLNAME{'}'}</span>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField className={classes.formField}
                                                                       error={errors.from && touched.from}
                                                                       label={t('From')}
                                                                       name="from"
                                                                       value={values.from}
                                                                       onChange={handleChange}
                                                                       onBlur={handleBlur}
                                                                       helperText={(errors.from && touched.from) && errors.from}
                                                                       margin="normal"
                                                                       InputProps={{
                                                                           readOnly: true,
                                                                       }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField className={classes.formField}
                                                                       autoFocus
                                                                       error={errors.subject && touched.subject}
                                                                       label={t('Subject')}
                                                                       name="subject"
                                                                       value={values.subject}
                                                                       onChange={handleChange}
                                                                       onBlur={handleBlur}
                                                                       helperText={(errors.subject && touched.subject) && errors.subject}
                                                                       margin="normal"
                                                            />
                                                        </Grid>
                                                        {!values.forceHtmlRaw && <Grid item>
                                                            <Typography variant="caption">{t('Body')}</Typography>
                                                        </Grid>
                                                        }
                                                        {!values.forceHtmlRaw && <Grid item>
                                                            <Field name="body">
                                                                {({field}) => <TextEditor value={field.value} onChange={field.onChange(field.name)}/>}
                                                            </Field>
                                                            {errors.body && <Typography variant="caption" color="error">{errors.body}</Typography>}
                                                        </Grid>
                                                        }
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        id="forceHtmlRaw"
                                                                        name="forceHtmlRaw"
                                                                        checked={values.forceHtmlRaw}
                                                                        value="forceHtmlRaw"
                                                                        color="primary"
                                                                        onChange={handleChange}
                                                                    />
                                                                }
                                                                label={t('Force Html Raw')}
                                                            /><br/>
                                                            { values.forceHtmlRaw && <TextField className={classes.formFieldWide}
                                                                                                label={t('Html Raw')}
                                                                                                variant="outlined"
                                                                                                error={errors.htmlRaw && touched.htmlRaw}
                                                                                                name="htmlRaw"
                                                                                                value={values.htmlRaw}
                                                                                                onChange={handleChange}
                                                                                                onBlur={handleBlur}
                                                                                                helperText={(errors.htmlRaw && touched.htmlRaw) && errors.htmlRaw}
                                                                                                margin="normal"
                                                                                                multiline
                                                                                                rows="5" />
                                                            }
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField className={classes.formField}
                                                                       error={errors.copyTo && touched.copyTo}
                                                                       label={t('CopyTo')}
                                                                       name="copyTo"
                                                                       value={values.copyTo}
                                                                       onChange={handleChange}
                                                                       onBlur={handleBlur}
                                                                       helperText={(errors.copyTo && touched.copyTo) && errors.copyTo}
                                                                       margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        id="copyToSender"
                                                                        name="copyToSender"
                                                                        checked={values.copyToSender}
                                                                        value="copyToSender"
                                                                        color="primary"
                                                                        onChange={handleChange}
                                                                    />
                                                                }
                                                                label={t('Send Copy To Sender')}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                type="submit"
                                                                disabled={isSubmitting}
                                                                color="primary"
                                                                variant="contained"
                                                            >
                                                                {t('Send Email Button')}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                            );
                                        }}
                                    </Formik>);
                            }
                            else {
                                return (
                                    <Grid container direction="column" justify="flex-start" alignItems="center" spacing={3}>
                                        <Grid item>
                                            <p>{t('Authentication is required for sending emails.')}</p>
                                        </Grid>
                                        <Grid item>
                                            <Button component={Link} to="/login" color="inherit">
                                                {t('login:Login Button')}
                                            </Button>
                                        </Grid>
                                    </Grid>);
                            }
                        }}
                    </AuthContext.Consumer>
                </Paper>
            </Dialog>
        </div>
    );
}

export default withTranslation('emails')(SendEmailDialog);

SendEmailDialog.propTypes = {
    recipientUserIds: PropTypes.array,
    recipients: PropTypes.array,
};

SendEmailDialog.defaultProps = {
    recipientUserIds: [],
    recipients: [],
};