import React from 'react';
import * as PropTypes from "prop-types";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

class TextEditor extends React.Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(value) {
        this.props.onChange(value);
    }

    render () {
        const modules = {
            toolbar: [
                [{ 'header': [1, 2, 3, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                //[{ 'align': [] }],
                //[{ 'indent': '-1'}, { 'indent': '+1' }],
                ['blockquote', 'code-block'],
                //[{ 'font': [] }],
                [{ 'color': [] }, { 'background': [] }],
                //[{ 'size': ['small', false, 'large', 'huge'] }],
                //[{ 'script': 'sub'}, { 'script': 'super' }],
                ['clean']
            ]
        };

        return (
            <ReactQuill
                theme="snow"
                modules={modules}
                value={this.props.value}
                onChange={this.handleChange} />
        );
    }
}

export default TextEditor;

TextEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
};