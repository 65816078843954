import React from 'react'
import * as PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";

import {CourseTypeFilters, SelectionModes} from "../models/CourseTypeFilters"
import SingleOptionFilter from "./SingleOptionFilter";
import MultiOptionFilter from "./MultiOptionFilter";
import {Typography} from "@material-ui/core";


function CourseTypeFilter(props) {
    const t = props.t;

    const unspecifiedOption = {
        value: SelectionModes.Unspecified,
        label: t("Mode Unspecified"),
        tooltip: "",
    };

    const noneOption = {
        value: SelectionModes.None,
        label: t("Mode None"),
        tooltip: "",
    };

    const anyOption = {
        value: SelectionModes.Any,
        label: t("Mode Any"),
        tooltip: "",
    };

    const allSelectedOption = {
        value: SelectionModes.AllSelected,
        label: t("Mode All Selected"),
        tooltip: "",
    };

    const anySelectedOption = {
        value: SelectionModes.AnySelected,
        label: t("Mode Any Selected"),
        tooltip: "",
    };

    const initModes = props.allowUnspecifiedMode
        ? [unspecifiedOption]
        : [];

    const modeOptions = props.enforceAnySelectedMode
        ? initModes.concat([anySelectedOption])
        : initModes.concat([noneOption, anyOption, anySelectedOption, allSelectedOption]);

    const selectOptions = props.courseTypes.map((type) => {
        return {
            value: type,
            label: type,
            tooltip: t('Course Type')+": "+type,
        };
    });

    const onModeChange = (mode) => {
        let modified = props.filters.withMode(mode);
        if (props.filters !== modified)
            props.onFilterChange(modified);
    };

    const onAllSelectionChange = (selected) => {
        let modified = props.filters.withAllSelected(selected);
        if (props.filters !== modified)
            props.onFilterChange(modified);
    };

    const onAnySelectionChange = (selected) => {
        let modified = props.filters.withAnySelected(selected);
        if (props.filters !== modified)
            props.onFilterChange(modified);
    };

    const hideModeSelection = props.enforceAnySelectedMode && !props.allowUnspecifiedMode;

    return (
        <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
            <Grid item>
                <Typography noWrap>{props.label}</Typography>
            </Grid>
            {   !hideModeSelection && (
                <Grid item>
                    <SingleOptionFilter
                        options={modeOptions}
                        selectedValue={props.filters.mode}
                        onSelectionChange={onModeChange}
                    />
                </Grid>)}
            {   props.filters.mode === SelectionModes.AllSelected && (
                <Grid item>
                    <MultiOptionFilter
                        disablePopup={hideModeSelection}
                        options={selectOptions}
                        selectedValues={props.filters.selected}
                        onSelectionChange={onAllSelectionChange}
                    />
                </Grid>)}
            {   props.filters.mode === SelectionModes.AnySelected && (
                <Grid item>
                    <MultiOptionFilter
                        disablePopup={hideModeSelection}
                        options={selectOptions}
                        selectedValues={props.filters.selected}
                        onSelectionChange={onAnySelectionChange}
                    />
                </Grid>)}
        </Grid>
    );
}

export default withTranslation('filters')(CourseTypeFilter);

CourseTypeFilter.propTypes = {
    label: PropTypes.string.isRequired,
    enforceAnySelectedMode: PropTypes.bool,
    allowUnspecifiedMode: PropTypes.bool,
    courseTypes: PropTypes.array.isRequired,
    filters: PropTypes.instanceOf(CourseTypeFilters).isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

CourseTypeFilter.defaultProps = {
    label: '',
    enforceAnySelectedMode: false,
    allowUnspecifiedMode: true,
    courseTypes: [],
    filters: CourseTypeFilters.unspecified(),
    onFilterChange: null,
};