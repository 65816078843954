class CollectionHelper {
    static setEquals(setA, setB) {
        if (setA == null && setB == null) return true;
        if (setA == null) return false;
        if (setB == null) return false;
        if (setA.size !== setB.size) return false;
        for (let a of setA) if (!setB.has(a))
            return false;
        return true;
    }
}

export default CollectionHelper;