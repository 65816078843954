import React from 'react';
import * as PropTypes from 'prop-types'
import './SplitPanel.css';

const SplitPanelContext = React.createContext(null);

class SplitPanel extends React.Component {
    static contextType = SplitPanelContext;

    constructor(props) {
        super(props);

        this.state = {
            topHeight: null,
            bottomHeight: null,
        };

        this.separatorPosY = null;
        this.containerRef = React.createRef();

        this.setTopHeight = this.setTopHeight.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
    }

    setTopHeight(value) {
        let splitPaneHeight = this.containerRef.current.clientHeight;
        if (splitPaneHeight > 0)
            this.setState((state, _props) => {
                if (state.topHeight === value)
                    return;

                return {
                    topHeight: value,
                    bottomHeight: splitPaneHeight - value,
                };
            });
    }

    handleMouseDown(e) {
        this.separatorPosY = e.clientY;
    };

    handleMouseMove(e) {
        if (this.separatorPosY == null) return;

        let newTopHeight = this.state.topHeight + e.clientY - this.separatorPosY;
        if (newTopHeight < this.props.minTopHeight)
            newTopHeight = this.props.minTopHeight;

        let splitPaneHeight = this.containerRef.current.clientHeight;
        let newBottomHeight = splitPaneHeight - newTopHeight;

        if (newBottomHeight > this.props.minBottomHeight) {
            this.separatorPosY = e.clientY;

            this.setState((state, _props) => {
                if (state.topHeight === newTopHeight &&
                    state.bottomHeight === newBottomHeight)
                    return state;

                return {
                    topHeight: newTopHeight,
                    bottomHeight: newBottomHeight,
                }
            });
        }
    };

    handleMouseUp () {
        this.separatorPosY = null;
    };

    componentDidMount() {
        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
    }

    componentWillUnmount() {
        document.removeEventListener("mousemove", this.handleMouseMove);
        document.removeEventListener("mouseup", this.handleMouseUp);
    }

    render() {
        const ctx = {
            state: this.state,
            setTopHeight: this.setTopHeight
        };

        return (
            <div className="split-pane" ref={this.containerRef}>
                <SplitPanelContext.Provider value={ctx}>
                    {this.props.children[0]}
                    <div className="separator" onMouseDown={this.handleMouseDown} />
                    {this.props.children[1]}
                </SplitPanelContext.Provider>
            </div>
        );
    }
}

SplitPanel.Top = function SplitPaneTop({children, ...props}) {
    const topRef = React.createRef();
    const ctx = React.useContext(SplitPanelContext);

    React.useEffect(() => {
        if (ctx.state.topHeight == null) {
            ctx.setTopHeight(topRef.current.clientHeight);
            topRef.current.style.flex = "none";
        }
        else
        {
            topRef.current.style.height = ctx.state.topHeight + "px";
        }
    }, [ctx, topRef]);

    return (
        <div {...props} className="split-pane-top" ref={topRef}>
            {children}
        </div>
    );
};

SplitPanel.Bottom = function SplitPaneBottom({children, ...props}) {
    return (
        <div {...props} className="split-pane-bottom">
            {children}
        </div>
    );
};

export default SplitPanel;
export { SplitPanelContext };

SplitPanel.propTypes = {
    minTopHeight: PropTypes.number.isRequired,
    minBottomHeight: PropTypes.number.isRequired,
};