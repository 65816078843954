import React from 'react'
import {withTranslation} from 'react-i18next';
import {Typography, Paper, Grid, Button} from "@material-ui/core";
import FetchHelper from "../helpers/FetchHelper";
import ValidationHelper from "../helpers/ValidationHelper";
import SendEmailGrid from "../components/SendEmailGrid";
import GridHelper from "../helpers/GridHelper";

class EmailsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columnDefs: this.getColumnDefs(),
            data: {
                recipients: []
            },
            recipientsRaw: ""
        };

        this.validator = new ValidationHelper(props.t);
        this.onRecipientsRawChanged = this.onRecipientsRawChanged.bind(this);
        this.onValidateRecipientsClick = this.onValidateRecipientsClick.bind(this);
    }

    onRecipientsRawChanged(event) {
        const text = event.target.value;

        this.setState((_prevState, _props) => {
            return {
                recipientsRaw: text
            };
        });
    }

    getColumnDefs(){
        const t = this.props.t;

        return [
            GridHelper.withCheckbox(GridHelper.textColumn(t("Email Header"),"email")),
            GridHelper.numberColumn(t("Entity ID Header"),"id"),
            GridHelper.textColumn(t("Full Name Header"), "fullname"),
        ];
    }

    render() {
        const t = this.props.t;

        return (
            <main>
                <Grid container direction="row" spacing={1} style={{ width: "100%", height: "800px" }}>
                    <Grid xs={4} item>
                        <Paper style={{padding:"10px"}}>
                            <Grid container direction="column" spacing={1} style={{ height: "100%" }}>
                                <Grid item>
                                    <Grid container direction="row" justify="space-between"  alignItems="center" spacing={2} wrap="nowrap">
                                        <Grid item>
                                            <Typography>
                                                {t('Emails or IDs Header')}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={this.onValidateRecipientsClick}
                                                variant="contained"
                                                disabled={this.state.recipientsRaw.length === 0}
                                                color="primary"
                                            >
                                                {t('Validate Button')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item style={{flex:1}}>
                                    <textarea
                                        style={{width:'98%',resize: 'vertical'}}
                                        onChange={this.onRecipientsRawChanged}
                                        value={this.state.recipientsRaw}
                                        rows={38}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid xs={8} item>
                        <SendEmailGrid
                            columnDefs={this.state.columnDefs}
                            rowData={this.state.data.recipients}
                            enforceUserIds={false}
                        />
                    </Grid>
                </Grid>
            </main>
        );
    }

    onValidateRecipientsClick(_event) {
        const [isValid, resOrMsg] = this.validator.requiredEmailOrIdList(this.state.recipientsRaw);
        if (!isValid) {
            window.alert(resOrMsg);
            return;
        }

        const body = JSON.stringify({
            recipients: resOrMsg
        });

        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body,
        };

        return FetchHelper.fetchJsonApi('/api/messaging/validate', request)
            .then(res => {
                console.log("[received] validated recipients, count: " + res.data.recipients.length);
                return res.data;
            })
            .then(data => this.applyRecipients(data.recipients))
            .catch(err => {
                console.log(err);
                this.applyRecipients([]);
                window.alert(err.message);
            });
    }

    applyRecipients(data)
    {
        this.setState((state, _props) => {
            return {
                data: { recipients: data },
                selectedRecipients: [],
                recipientsRaw: ""
            };
        });
    }
}

export default withTranslation()(EmailsPage);