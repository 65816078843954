import React from 'react'
import * as PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell';
import ScrollableTable from "./ui/ScrollableTable";
import CourseFilters from "../models/CourseFilters";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";

function CourseList(props) {
    const selected = Array.from(props.filters.selectedCourses);

    const isSelected = row => selected.indexOf(row.id) !== -1;

    const setSelected = (row, newState) => {
        let newSelected = new Set(props.filters.selectedCourses);
        if (newState)
            newSelected.add(row.id);
        else
            newSelected.delete(row.id);
        props.onFilterChange(props.filters
            .withSelectedCourses(newSelected));
    };

    const setSelectedAll = newState => {
        let newSelected = newState
            ? new Set(props.courses.map(c => c.id))
            : new Set();
        props.onFilterChange(props.filters
            .withSelectedCourses(newSelected));
    };

    const headCells = [
        { id: 'id', numeric: false, disablePadding: true, label: 'Id' },
        { id: 'code', numeric: false, disablePadding: true, label: 'Code' },
        { id: 'name', numeric: false, disablePadding: true, label: 'Course Name' },
        { id: 'hasVenue', numeric: false, disablePadding: true, label: 'TV' },
    ];

    const rowCellsRenderer = (table, row, index) => {
        const isItemSelected = isSelected(row);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
            <TableRow
                hover
                onClick={event => table.handleClick(event, row)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{'aria-labelledby': labelId}}
                        style={{color:row.color}}
                    />
                </TableCell>
                <TableCell component="th" id={labelId} scope="row" padding="none">
                    {row.id}
                </TableCell>
                <TableCell align="right">{row.code}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="right">{row.hasVenue ? 'Y' : 'N'}</TableCell>
            </TableRow>
        );
    };

    return (
        <ScrollableTable
            title="Courses"
            enableToolbar={false}
            rows={props.courses}
            headCells={headCells}
            rowCellsRenderer={rowCellsRenderer}
            selected={selected}
            isSelected={isSelected}
            setSelected={setSelected}
            setSelectedAll={setSelectedAll}
            initialOrderBy={'courseId'}
            scrollHeight={props.scrollHeight}
        />
    );
}

export default CourseList;

CourseList.propTypes = {
    filters: PropTypes.instanceOf(CourseFilters).isRequired,
    courses: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    scrollHeight: PropTypes.number.isRequired,
};

CourseList.defaultProps = {
    filters: CourseFilters.empty(),
    courses: []
};