import React from 'react'
import * as PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LocationFilter from "./LocationFilter";
import CourseTypeFilterEx from "./CourseTypeFilter";
import FetchHelper from "../helpers/FetchHelper";
import {CountryFilter} from "../models/LocationFilters";
import {UserFilters, UserRoleFilters, UserSelectionFilters} from "../models/UserFilters";
import MultiOptionFilter from "./MultiOptionFilter";

const emptyState = () => {
    return {
        data: {
            courseTypes: [],
        }
    };
};

class UserFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = emptyState();

        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleIncludeChange = this.handleIncludeChange.bind(this);
        this.handleRequireChange = this.handleRequireChange.bind(this);
        this.handleHasCompletedChange = this.handleHasCompletedChange.bind(this);
        this.handleHasNotCompletedChange = this.handleHasNotCompletedChange.bind(this);
        this.handleHasSignedForChange = this.handleHasSignedForChange.bind(this);
        this.handleHasNotSignedForChange = this.handleHasNotSignedForChange.bind(this);
    }

    handleLocationChange(filters) {
        let modified = this.props.filters.withLocation(filters);
        if (modified !== this.props.filters) {
            if (filters.countryIso2 !== this.props.filters.location.countryIso2)
                modified = modified.clearActionSelection();

            this.props.onFilterChange(modified);
        }
    }

    handleIncludeChange(include) {
        let modified = this.props.filters.withInclude(include);
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    handleRequireChange(require) {
        let modified = this.props.filters.withRequire(require);
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    handleHasCompletedChange(filters) {
        console.log(filters.toLogString());

        let modified = this.props.filters.withHasCompleted(filters);
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    handleHasNotCompletedChange(filters) {
        console.log(filters.toLogString());

        let modified = this.props.filters.withHasNotCompleted(filters);
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    handleHasSignedForChange(filters) {
        console.log(filters.toLogString());

        let modified = this.props.filters.withHasSignedFor(filters);
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    handleHasNotSignedForChange(filters) {
        console.log(filters.toLogString());

        let modified = this.props.filters.withHasNotSignedFor(filters);
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    applyCourseTypes(data) {
        this.setState((state, _props) => {
            return {
                data: {
                    courseTypes: data,
                }
            };
        });
    }

    fetchCourseTypes() {
        FetchHelper.fetchJsonApi('/api/courses/types', { method: 'GET' })
            .then(res => {
                console.log("[received] course type info, visible: " + res.data.visible.length +
                    ', active: ' + res.data.active.length + ', finished: '+res.data.finished.length +
                    ', byCountry: ' + res.data.byCountry.length);
                this.applyCourseTypes(res.data);
            })
            .catch(err => {
                console.log(err);
                this.applyCourseTypes({
                    visible: [],
                    active: [],
                    finished: [],
                    byCountry: [],
                });
                window.alert(err.message);
            });
    }

    componentDidMount() {
        this.fetchCourseTypes();
    }

    render() {
        const t = this.props.t;

        const includeOptions = UserSelectionFilters.allSelections.map(selection => {
            return {
                value: selection,
                label: t("Selection "+selection),
                tooltip: "",
            };
        });

        const requireOptions = UserRoleFilters.allRoles.map(role => {
            return {
                value: role,
                label: t("Role "+role),
                tooltip: "",
            };
        });

        let finished = this.state.data.courseTypes.finished;
        let visible = this.state.data.courseTypes.visible;
        let country = this.props.filters.location.countryIso2;
        if (country != null) {
            const byCountry =
                this.state.data.courseTypes.byCountry
                .find(el => el.country === country);
            if (byCountry != null) {
                finished = byCountry.finished;
                visible = byCountry.visible;
            }
            else {
                finished = [];
                visible = [];
            }
        }

        return (
            <Paper style={{padding: "10px"}}>
                <Grid container direction="column" justify="center" alignItems="flex-start" spacing={1}>
                    <Grid>
                        <LocationFilter
                            filterCountries={CountryFilter.WithActions}
                            filters={this.props.filters.location}
                            onFilterChange={this.handleLocationChange}
                            enableRegions={true}
                            enableDistricts={true}
                        />
                    </Grid>
                    <Grid item>
                        <i className="hint">{t("Select Users")}</i>
                    </Grid>
                    <Grid item>
                        <MultiOptionFilter
                            options={includeOptions}
                            selectedValues={this.props.filters.include}
                            onSelectionChange={this.handleIncludeChange}
                        />
                    </Grid>
                    <Grid item>
                        <i className="hint">{t("Filter by User Roles")}&nbsp;</i>
                    </Grid>
                    <Grid item>
                        <MultiOptionFilter
                            options={requireOptions}
                            selectedValues={this.props.filters.require}
                            onSelectionChange={this.handleRequireChange}
                        />
                    </Grid>
                    <Grid item>
                        <i className="hint">{t("Filter by Course Registrations")}&nbsp;</i>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <CourseTypeFilterEx
                                    label={t('Has Completed')}
                                    courseTypes={finished}
                                    filters={this.props.filters.hasCompleted}
                                    onFilterChange={this.handleHasCompletedChange}
                                />
                            </Grid>
                            <Grid item>
                                <CourseTypeFilterEx
                                    label={t('Has Not Completed')}
                                    enforceAnySelectedMode={true}
                                    courseTypes={finished}
                                    filters={this.props.filters.hasNotCompleted}
                                    onFilterChange={this.handleHasNotCompletedChange}
                                />
                            </Grid>
                            <Grid item>
                                <CourseTypeFilterEx
                                    label={t('Has Signed For')}
                                    courseTypes={visible}
                                    filters={this.props.filters.hasSignedFor}
                                    onFilterChange={this.handleHasSignedForChange}
                                />
                            </Grid>
                            <Grid item>
                                <CourseTypeFilterEx
                                    label={t('Has Not Signed For')}
                                    enforceAnySelectedMode={true}
                                    courseTypes={visible}
                                    filters={this.props.filters.hasNotSignedFor}
                                    onFilterChange={this.handleHasNotSignedForChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withTranslation('filters')(UserFilter);

UserFilter.propTypes = {
    filters: PropTypes.instanceOf(UserFilters).isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

UserFilter.defaultProps = {
    filters: UserFilters.empty(),
    onFilterChange: null,
};