import React from 'react'
import {withTranslation} from "react-i18next";
import {Link} from 'react-router-dom'
import Button from "@material-ui/core/Button";
import {AuthContext} from "../helpers/AuthenticationService";

class LoginAvatar extends React.Component {
    render() {
        const t = this.props.t;

        return (
            <AuthContext.Consumer>
            { value => {
                const auth = value.auth();
                if (auth.isAuthenticated) {
                    return (
                        <Button color="inherit" onClick={value.logout}>{auth.user.fullname + ' - ' + t('Logout Button')}</Button>
                    );
                }
                else {
                    return (
                        <Button component={Link} to="/login" color="inherit">
                            {t('Login Button')}
                        </Button>
                    );
                }
            }}
            </AuthContext.Consumer>
        );
    }
}

export default withTranslation('login')(LoginAvatar);
