import React from 'react'
import {withTranslation} from 'react-i18next';
import Button from "@material-ui/core/Button";

class DisplayHtmlPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayUrl: "../resources/pl/courses-info.html"
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(newUrl) {
        console.log(newUrl);

        this.setState((_prevState, _props) => {
            return {
                displayUrl: newUrl
            };
        });
    }

    render() {
        return (
            <main>
                <Button onClick={() => this.handleChange('../resources/pl/courses-info.html')}>embeded</Button>
                <Button onClick={() => this.handleChange('http://localhost:8080/?page_id=2')}>wordpress</Button>
                <iframe title="courses-info" src={this.state.displayUrl} style={{width:"100%", height:"100%",borderStyle:"none"}}/>
            </main>
        );
    }
}

export default withTranslation()(DisplayHtmlPage);