import React from 'react'
import {withTranslation} from 'react-i18next';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import UserFilter from "../components/UserFilter";
import UserInfoGrid from "../components/UserRegistrationsGrid";
import {UserFilters} from "../models/UserFilters";
import FetchHelper from "../helpers/FetchHelper";

const emptyState = () => {
    return {
        data: {
            registrations: new Set()
        },
        filters: UserFilters.empty()
    };
};

class RegistrationsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = emptyState();

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleRefreshClick = this.handleRefreshClick.bind(this);
        this.handleClearFilterClick = this.handleClearFilterClick.bind(this);
    }

    handleFilterChange(filters) {
        console.log(filters.toLogString());

        this.setState((_state, _props) => {
            return {
                filters: filters
            };
        })
    }

    handleClearFilterClick(_e) {
        this.handleFilterChange(UserFilters.empty());
    }

    handleRefreshClick(_e) {
        const filter = this.state.filters.toApiModel();
        this.fetchFilteredUserIds(filter);
    }

    applyRegistrations(data)
    {
        this.setState((state, _props) => {
            return {
                data: {
                    registrations: data,
                }
            }
        });
    }

    fetchFilteredUserIds(filters) {
        const body = JSON.stringify(filters);
        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body,
        };

        return FetchHelper.fetchJsonApi('/api/filtering/userIds', request)
            .then(res => {
                console.log("[received] filtered users, ids: " + res.data.count);
                return res.data;
            })
            .then(data => {
                if (data.count > 0)
                    this.applyRegistrations(new Set(data.userIds));
                else
                    this.applyRegistrations(new Set());
            })
            .catch(err => {
                console.log(err);
                window.alert(err.message);
            });
    }

    render() {
        const t = this.props.t;
        const isFilterValid = this.state.filters.isValid();

        return (
            <main>
                <Grid container direction="column" style={{width: '100%', height: '100%'}} spacing={1}>
                    <Grid item>
                        <UserFilter
                            filters={this.state.filters}
                            onFilterChange={this.handleFilterChange}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item>
                                <Button
                                    onClick={this.handleRefreshClick}
                                    variant="contained"
                                    color="primary"
                                    disabled={!isFilterValid}
                                >
                                    {t('Refresh Data Button')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={this.handleClearFilterClick}
                                    variant="outlined"
                                    color="primary"
                                >
                                    {t('Clear Filters Button')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{flex: 1}}>
                        <UserInfoGrid
                            userIds={this.state.data.registrations}
                        />
                    </Grid>
                </Grid>
            </main>
        );
    }
}

export default withTranslation()(RegistrationsPage);