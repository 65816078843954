import React from 'react'
import {withTranslation} from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';
import { AuthContext} from "../helpers/AuthenticationService";

class LoginPage extends React.Component {
    render() {
        const t = this.props.t;

        const validate = (values) => {
            let errors = {};

            if (!values.login)
                errors.login = t('Required field');

            if (isNaN(values.login) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login))
                errors.login = t('Invalid email address or SID');

            if (!values.password)
                errors.password = t('Required field');

            return errors;
        };

        return (
            <main>
                <AuthContext.Consumer>
                { value => {
                    const auth = value.auth();
                    if (auth.isAuthenticated)
                        return (
                            <Redirect to={{pathname: '/', state: {from: this.props.location.state}}}/>
                        );
                    else
                        return(
                            <div>
                                <h1>{t('Login Title')}</h1>
                                <Paper style={{width:"400px", textAlign:"center", padding:15}}>
                                    <Formik
                                        initialValues={{login: '', password: ''}}
                                        onSubmit={(values, {setSubmitting}) => {
                                            setSubmitting(true);
                                            value.login(values.login, values.password)
                                                .then(_res => {
                                                    setSubmitting(false);
                                                    const {from} = this.props.location.state || {from: {pathname: "/"}};
                                                    this.props.history.push(from);
                                                })
                                                .catch(err => {
                                                    setSubmitting(false);

                                                    if (err.status === 401)
                                                        if (err.error === "InvalidLogin")
                                                            window.alert(t('Invalid email address or SID'));
                                                        else
                                                            window.alert(t('Invalid credentials'));
                                                    else
                                                        window.alert(err.message);
                                                });
                                        }}
                                        validate={validate}
                                    >
                                        {props => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                isSubmitting,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                            } = props;
                                            return (
                                                <form onSubmit={handleSubmit} autoComplete="off">
                                                    <TextField
                                                        autoFocus
                                                        error={errors.login && touched.login}
                                                        label={t('Email or SID')}
                                                        name="login"
                                                        value={values.login}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={(errors.login && touched.login) && errors.login}
                                                        margin="normal"
                                                    />
                                                    <br/>
                                                    <TextField
                                                        error={errors.password && touched.password}
                                                        label={t('Password')}
                                                        name="password"
                                                        type="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={(errors.password && touched.password) && errors.password}
                                                        margin="normal"
                                                    />
                                                    <br/>
                                                    <br/>
                                                    <Button
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                        color="primary"
                                                        variant="contained"
                                                    >
                                                        {t('Login Button')}
                                                    </Button>
                                                </form>
                                            );
                                        }}
                                    </Formik>
                                </Paper>
                            </div>
                        );
                }}
                </AuthContext.Consumer>
            </main>
        );
    }
}

export default withTranslation('login')(LoginPage);
