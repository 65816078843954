import React from 'react'
import * as PropTypes from 'prop-types'
import './MapView.css';
import Map from 'ol/Map';
import View from 'ol/View';
import {ZoomSlider} from "ol/control";
import {Tile as TileLayer} from 'ol/layer';
import {OSM} from 'ol/source';
import {fromLonLat} from 'ol/proj'

class MapView extends React.Component {
    constructor(props) {
        super(props);

        this.olMap = new Map({
            view: new View({
                center: fromLonLat([props.center.lng, props.center.lat]),
                zoom: props.zoom,
            }),
            layers: [
                new TileLayer({ source: new OSM()})
            ],
            target: null
        });

        this.olMap.addControl(new ZoomSlider());

        this.mapElementRef = React.createRef();
        this.updateMapSize = this.updateMapSize.bind(this);
    }

    updateMapSize() {
        if (this.olMap != null)
            this.olMap.updateSize();
    }

    componentDidMount() {
        this.olMap.setTarget(this.mapElementRef.current);
        window.onresize = this.updateMapSize;

        if (this.props.onMapInitialized != null)
            this.props.onMapInitialized(this.olMap);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.knownHeight != null &&
            this.props.knownHeight != null &&
            this.props.knownHeight !== prevProps.knownHeight)
            this.updateMapSize();
    }

    render() {
        return (
            <div id="olMap" className="olMap" ref={this.mapElementRef} />
        );
    }
}

export default MapView;

MapView.propTypes = {
    zoom: PropTypes.number.isRequired,
    center: PropTypes.object.isRequired,
    onMapInitialized: PropTypes.func,
    knownHeight: PropTypes.number,
};

MapView.defaultProps = {
    zoom: 2,
    center: {
        lat: 49.19493,
        lng: 16.61239
    },
    onMapInitialized: null,
    knownHeight: null,
};