import React from 'react';
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: 0,
    },
    paper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(1),
    },
    table: {

    },
    tableWrapper: {
        overflow: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

class ScrollableTable extends React.Component {
    constructor(props) {
        super(props);

        this.classes = this.props.classes;
        this.state = {
            order: 'asc',
            orderBy: props.initialOrderBy,
        };

        this.setOrderBy = this.setOrderBy.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    setOrderBy(orderBy, order) {
        this.setState((state, _props) => {
            return {
                order: order,
                orderBy: orderBy,
                page: state.page,
                rowsPerPage: state.rowsPerPage,
            };
        })
    }

    handleRequestSort(event, property) {
        const isDesc =
            this.state.orderBy === property &&
            this.state.order === 'desc';
        this.setOrderBy(property, isDesc ? 'asc' : 'desc');
    }

    handleSelectAllClick(event) {
        this.props.setSelectedAll(event.target.checked);
    }

    handleClick(event, row) {
        this.props.setSelected(row, !this.props.isSelected(row));
    }

    render() {
        const classes = this.classes;
        const rows = this.props.rows;
        const displayRows = this.props.displayRows;
        const emptyRows = displayRows - Math.min(displayRows, rows.length);
        const order = this.state.order;
        const orderBy = this.state.orderBy;

        console.log('table render');

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    {
                        this.props.enableToolbar && (
                            <EnhancedTableToolbar
                                title={this.props.title}
                                numSelected={this.props.selected.length}
                            />)
                    }
                    <div className={classes.tableWrapper} style={{maxHeight: this.props.scrollHeight}}>
                        <Table stickyHeader
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'small'}
                        >
                            <EnhancedTableHead
                                headCells={this.props.headCells}
                                classes={classes}
                                numSelected={this.props.selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={this.handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {stableSort(rows, getSorting(order, orderBy))
                                    .slice(0, 500)
                                    .map((row, index) => this.props.rowCellsRenderer(this, row, index))
                                }
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 33 * emptyRows}}>
                                        <TableCell colSpan={this.props.headCells.length + 1}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </div>
        );
    }
}

ScrollableTable.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    enableToolbar: PropTypes.bool.isRequired,
    rows: PropTypes.array.isRequired,
    headCells: PropTypes.array.isRequired,
    rowCellsRenderer: PropTypes.func.isRequired,
    selected: PropTypes.array.isRequired,
    isSelected: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    setSelectedAll: PropTypes.func.isRequired,
    initialOrderBy: PropTypes.string.isRequired,
    scrollHeight: PropTypes.number.isRequired,
};

export default withStyles(styles)(ScrollableTable);