import React from 'react'
import * as PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import CollectionHelper from "../helpers/CollectionHelper";
import FetchHelper from "../helpers/FetchHelper";
import DataCache from "../helpers/DataCache";
import SendEmailGrid from "./SendEmailGrid";
import GridHelper from "../helpers/GridHelper";


class UserRegistrationsGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columnDefs: this.getColumnDefs(),
            data: {
                registrations: []
            }
        };

        this.registrationsCache = DataCache.empty();
    }

    getColumnDefs(){
        const t = this.props.t;

        return [
            GridHelper.withCheckbox(GridHelper.numberColumn(t("Entity ID Header"),"userId")),
            GridHelper.textColumn(t("Full Name Header"), "fullname"),
            GridHelper.textColumn(t("Registered Header"), "registered"),
            GridHelper.textColumn(t("Completed Header"), "completed"),
            GridHelper.textColumn(t("Contests Header"), "contests"),
            GridHelper.textColumn(t("Zip Header"), "zip"),
            GridHelper.textColumn(t("Email Header"), "email"),
            GridHelper.textColumn(t("Phone Header"), "phone"),
            GridHelper.boolColumn(t("Internet Header"), "internet"),
            GridHelper.dateColumn(t("LastLogonAt Header"), "lastLogonAt"),
        ];
    }

    render() {
        return (
            <SendEmailGrid
                columnDefs={this.state.columnDefs}
                rowData={this.state.data.registrations}
                enforceUserIds={true}
            />
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!CollectionHelper.setEquals(this.props.userIds, prevProps.userIds)) {
            this.fetchRegistrations(this.props.userIds);
        }
    }

    applyRegistrations(data)
    {
        this.setState((state, _props) => {
            const mapped = data
                .map(p => {
                    let date = null;
                    if (p.lastLogonAt)
                        date = new Date(p.lastLogonAt);

                    return {
                        userId: p.userId,
                        fullname: p.fullname,
                        email: p.email,
                        phone: p.phone,
                        zip: p.zip,
                        internet: p.internet,
                        lastLogonAt: date,
                        registered: p.registered.map(c => c.code).join(' '),
                        completed: p.completed.map(c => c.code).join(' '),
                        contests: p.contests.map(c => c.contestId).join(' '),
                    };
                });

            return {
                data: { registrations: mapped },
                selectedUserIds: []
            };
        });
    }

    fetchRegistrations(userIds) {
        const fetchFromServer = (keys) => {
            const body = JSON.stringify(Array.from(keys));
            const request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body,
            };

            return FetchHelper.fetchJsonApi('/api/registrations/listUserRegistrationsById', request)
                .then(res => {
                    console.log("[received] user registrations, ids: " + res.data.length);
                    return res.data;
                });
        };

        this.registrationsCache
            .fetchForKeys(userIds, fetchFromServer, (i) => i.userId)
            .then(data => this.applyRegistrations(data))
            .catch(err => {
                console.log(err);
                this.applyRegistrations([]);
                window.alert(err.message);
            });
    }
}

export default withTranslation()(UserRegistrationsGrid);

UserRegistrationsGrid.propTypes = {
    userIds: PropTypes.instanceOf(Set).isRequired,
};

UserRegistrationsGrid.defaultProps = {
    userIds: new Set(),
};