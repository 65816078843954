import FetchHelper from "./FetchHelper";
import React from "react";
import Cookies from "js-cookie";

export class Role {
    static User = "user";
    static Student = "student";
    static Teacher = "teacher";
    static Administrator = "administrator";
}

export const AuthContext = React.createContext(null);

export class AuthenticationService {
    constructor() {
        this.loggedOut = Object.freeze({
            isAuthenticated: false,
            user: null,
        });
    }

    login(login, password) {
        const body = JSON.stringify({
            login: login,
            password: password,
            persistence: "cookie",
        });

        const request = {
            method: 'POST',
            body: body,
        };

        return FetchHelper.fetchJsonApi("/api/authentication/login", request)
            .then(user => {
                return {
                    isAuthenticated: true,
                    user: user.data,
                };
            });
    }

    init() {
        const sgp_user = Cookies.get('sgp_user');
        if (sgp_user) {
            const data = JSON.parse(sgp_user);
            if (data)
                return {
                    isAuthenticated: true,
                    user: data,
                };
        }
        return this.loggedOut;
    }

    logout() {
        const request = {
            method: 'GET',
            credentials: 'include',
        };

        return FetchHelper.fetchJsonApi("/api/authentication/logout", request)
            .then(_res => {
                return this.loggedOut;
            });
    }
}