import React from 'react'
import * as PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {Button, TextField, Typography, Grid} from "@material-ui/core";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import SendEmailDialog from "./SendEmailDialog";
import ValidationHelper from "../helpers/ValidationHelper";

class SendEmailGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filteredRows: 0,
            selectedRows: 0,
            selectedRecipientRows: 0,
            selectedRecipientIds: [],
            selectedRecipients: [],
        };

        this.onGridReady = this.onGridReady.bind(this);
        this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onQuickFilterChanged = this.onQuickFilterChanged.bind(this);
        this.onInvertSelectionClicked = this.onInvertSelectionClicked.bind(this);
    }

    onGridReady(params){
        console.log('grid ready');
        this.gridApi = params.api;
    }

    onFirstDataRendered(params) {
        console.log('first data rendered');
        params.api.sizeColumnsToFit();
    }

    onQuickFilterChanged(e)
    {
        this.gridApi.setQuickFilter(e.target.value);
        this.handleGridChange();
    }

    onSelectionChanged(_e) {
        this.handleGridChange();
    }

    onFilterChanged(_e) {
        this.handleGridChange();
    }

    onInvertSelectionClicked(_e) {
        this.gridApi.forEachNodeAfterFilter((node, _index) => {
            if (node.isSelected())
                node.setSelected(false, false, true);
            else
                node.setSelected(true, false, true);
        });
        this.handleGridChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.rowData !== this.props.rowData)
            this.handleGridChange();
    }

    handleGridChange() {
        const filteredRows = this.gridApi.getModel().rootNode.childrenAfterFilter.length;
        const selectedNodes = this.gridApi.getSelectedNodes();
        const selectedRows = selectedNodes.length;
        let selectedRecipientRows = 0;
        let selectedRecipientIds = [];
        let selectedRecipients = [];

        if (this.props.enforceUserIds) {
            selectedRecipientIds = selectedNodes
                .filter(r => ValidationHelper.isEmail(r.data.email))
                .map(r => r.data.userId);
            selectedRecipientRows = selectedRecipientIds.length;
        }
        else {
            selectedRecipients = selectedNodes
                /* .filter(r => this.validator.isEmail(r.data.email)) - API returns validated data */
                .map(r => r.data);
            selectedRecipientRows = selectedRecipients.length;
        }

        this.setState((_prevState, _props) => {
            return {
                filteredRows: filteredRows,
                selectedRows: selectedRows,
                selectedRecipientRows: selectedRecipientRows,
                selectedRecipientIds: selectedRecipientIds,
                selectedRecipients: selectedRecipients,
            };
        });
    }

    render() {
        const t = this.props.t;

        const totalRows = this.props.rowData.length;
        const filteredRows = this.state.filteredRows;
        const selectedRows = this.state.selectedRows;
        const selectedRecipientRows = this.state.selectedRecipientRows;

        return (
            <Grid container direction="column" style={{ width: "100%", height: "100%" }}>
                <Grid item>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} wrap="nowrap">
                        <Grid item>
                            <TextField
                                onChange={this.onQuickFilterChanged}
                                label={t('Quick filter')}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={this.onInvertSelectionClicked}>
                                {t("Invert Selected Rows")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography noWrap>{ t("Total Rows") }:&nbsp;<b>{totalRows}</b></Typography>
                        </Grid>
                        <Grid item>
                            <Typography noWrap>{ t("Filtered Rows") }:&nbsp;<b>{filteredRows}</b></Typography>
                        </Grid>
                        <Grid item>
                            <Typography noWrap>{ t("Selected Rows") }:&nbsp;<b>{selectedRows}</b></Typography>
                        </Grid>
                        <Grid item>
                            <Typography noWrap>{ t("Selected Recipient Rows") }:&nbsp;<b>{selectedRecipientRows}</b></Typography>
                        </Grid>
                        <Grid item>
                            <SendEmailDialog
                                disabled={selectedRecipientRows === 0}
                                recipientUserIds={this.state.selectedRecipientIds}
                                recipients={this.state.selectedRecipients}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{flex: 1}}>
                    <div className="ag-theme-balham" style={ {height: '100%', width: '100%'} }>
                        <AgGridReact
                            columnDefs={this.props.columnDefs}
                            rowData={this.props.rowData}
                            onGridReady={this.onGridReady}
                            onFirstDataRendered={this.onFirstDataRendered}
                            onSelectionChanged={this.onSelectionChanged}
                            onFilterChanged={this.onFilterChanged}
                            rowSelection='multiple'
                            suppressRowClickSelection={true}
                            enableCellTextSelection={true}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withTranslation()(SendEmailGrid);

SendEmailGrid.propTypes = {
    columnDefs: PropTypes.array.isRequired,
    rowData: PropTypes.array.isRequired,
    enforceUserIds: PropTypes.bool.isRequired,
};

SendEmailGrid.defaultProps = {
    columnDefs: [],
    rowData: [],
    enforceUserIds: true,
};