export class CountryFilter {
    static All = "all";
    static WithActions = "withActions";
    static WithEntities = "withEntities";
}

export class LocationFilters {
    constructor(rev, countryIso2, regionIso, districtId)
    {
        this.rev = rev;
        this.countryIso2 = countryIso2;
        this.regionIso = regionIso;
        this.districtId = districtId;
    }

    static empty() {
        return new LocationFilters(0, null, null, null);
    }

    toLogString() {
        return `[location filter] #${this.rev}, country: ${this.countryIso2}, region: ${this.regionIso}, district: ${this.districtId}`;
    }

    toApiModel() {
        return {
            countryIso2: this.countryIso2,
            regionIso: this.regionIso,
            districtId: this.districtId
        }
    }

    withCountry(country) {
        if (this.countryIso2 !== country)
            return new LocationFilters(this.rev + 1, country, null, null);
        else
            return this;
    }

    withRegion(region) {
        if (this.regionIso !== region)
            return new LocationFilters(this.rev + 1, this.countryIso2, region, null);
        else
            return this;
    }

    withDistrict(district) {
        if (this.districtId !== district)
            return new LocationFilters(this.rev + 1, this.countryIso2, this.regionIso, district);
        else
            return this;
    }
}