import React from 'react'
import CourseFilters from "../models/CourseFilters"
import CourseFilter from "../components/CourseFilter";
import CourseRegistrationsMap from "../components/CourseRegistrationsMap";
import CourseList from "../components/CourseList";
import SplitPanel, {SplitPanelContext} from "../components/ui/SplitPanel";
import FetchHelper from "../helpers/FetchHelper";
import UserInfoGrid from "../components/UserRegistrationsGrid";
import CollectionHelper from "../helpers/CollectionHelper";

const zoom = 7;
const brno = {
    lat: 49.19493,
    lng: 16.61239,
};

const emptyState = () => {
    return {
        data: {
            courseTypes: [],
            registrations: []
        },
        filters: CourseFilters.empty(),
        registrationsByType: [],
        coursesByType: [],
        selectedCourses: [],
        selectedParticipantIds: new Set()
    };
};

const errorState = (message) => {
    window.alert(message);
    return emptyState();
};

class CoursesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = emptyState();

        this.withFilters = this.withFilters.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleParticipantsChange = this.handleParticipantsChange.bind(this);
    }

    withFilters(filters, state, _props, current) {
        console.log(filters.toLogString());

        let modified = filters;

        // handle selected country change?
        if (filters.location.countryIso2 != null &&
            filters.location.countryIso2 !== state.filters.location.countryIso2)
        {
            // pre-select course types
            modified = filters
                .preselectCourseTypes(current.data.courseTypes);

            // request fetching new registrations data
            current.registrationsByType = [];
            current.coursesByType = [];
            current.selectedCourses = [];
            current.selectedParticipantIds = new Set();
            this.fetchRegistrations(filters.location.countryIso2);
        }

        // handle other filter changes?
        else if (filters.rev !== state.filters.rev)
        {
            current.registrationsByType = filters
                .filterRegistrationsBySelectedType(current.data.registrations);
            current.coursesByType = filters
                .filterCoursesBySelectedType(current.registrationsByType);
            current.selectedCourses = filters
                .filterCoursesBySelectedIds(current.coursesByType);
            current.selectedParticipantIds = new Set();
        }

        console.log(modified.toLogString());
        current.filters = modified;

        return current;
    }

    applyCourseTypes(data) {
        this.setState((state, _props) => {
            if (data.length === 0)
                return errorState('Unable to retrieve course data');

            // first country in the list is pre-selected
            let filters = state.filters
                .withSelectedCountry(data[0].country)
                .preselectCourseTypes(data);

            return this.withFilters(filters, state, _props, {
                data: {
                    courseTypes: data,
                    registrations: [],
                },
                selectedParticipantIds: new Set(),
            });
        });
    }

    applyRegistrations(data) {
        this.setState((state, _props) => {
            let filters = state.filters
                .preselectCourses(data);

            return this.withFilters(filters, state, _props,{
                data: {
                    courseTypes: state.data.courseTypes,
                    registrations: data,
                },
                selectedParticipantIds: new Set(),
            });
        });
    }

    handleFilterChange(filters) {
        this.setState((state, _props) => {
            return this.withFilters(filters, state, _props, state);
        });
    }

    handleParticipantsChange(participants) {
        this.setState((state, _props) => {
            console.log(`[filters] participants: [${Array.from(participants).join(',')}]`);

            if (CollectionHelper.setEquals(state.selectedParticipantIds, participants))
                return state;
            else {
                return {
                    data: state.data,
                    filters: state.filters,
                    selectedParticipantIds: participants,
                };
            }
        });
    }

    componentDidMount() {
        this.fetchCourseTypes();
    }

    fetchCourseTypes() {
        const request = {
            method: 'GET'
        };

        FetchHelper.fetchJsonApi("/api/courses/types", request)
            .then(res => {
                let data = res.data.byCountry;

                console.log("[received] course types, countries: " + data.length);
                this.applyCourseTypes(data);
                return data.length > 0 ? data[0].country : null;
            })
            .then(country => {
                if (country != null) this.fetchRegistrations(country);
            })
            .catch(err => {
                console.log(err);
                this.applyCourseTypes([]);
                window.alert(err.message);
            });
    }

    fetchRegistrations(country) {
        const request = {
            method: 'GET'
        };

        FetchHelper.fetchJsonApi('/api/courses/'+country+'/registrationsByCode', request)
            .then(res => {
                console.log("[received] course registrations, country: "+country+", types: " + res.data.length);
                this.applyRegistrations(res.data);
            })
            .catch(err => {
                console.log(err);
                this.applyRegistrations([]);
                window.alert(err.message);
            });
    }

    render() {
        const countries = this.state.data.courseTypes.map(el => el.country);
        const courseTypesOrNull = this.state.data.courseTypes
            .find(el => el.country === this.state.filters.location.countryIso2);
        const courseTypes = courseTypesOrNull != null
            ? courseTypesOrNull.visible
            : [];

        return (
            <main>
                <div style={{minHeight:"50px"}}>
                    <CourseFilter
                        countries={countries}
                        courseTypes={courseTypes}
                        filters={this.state.filters}
                        onFilterChange={this.handleFilterChange}
                    />
                </div>
                <SplitPanel minTopHeight={100} minBottomHeight={150}>
                    <SplitPanel.Top>
                        <SplitPanelContext.Consumer>
                            { value => {
                                const tableHeight = value.state.topHeight - 5;

                                return (
                                    <div className="full-height-cols">
                                        <div className="sidebar">
                                            <CourseList
                                                filters={this.state.filters}
                                                courses={this.state.coursesByType}
                                                onFilterChange={this.handleFilterChange}
                                                scrollHeight={tableHeight}
                                            />
                                        </div>

                                        <CourseRegistrationsMap
                                            center={brno} zoom={zoom}
                                            filters={this.state.filters}
                                            courses={this.state.selectedCourses}
                                            registrations={this.state.registrationsByType}
                                            onParticipantsChange={this.handleParticipantsChange}
                                            knownHeight={value.state.topHeight}
                                        />
                                    </div>
                                );
                            }}
                        </SplitPanelContext.Consumer>
                    </SplitPanel.Top>
                    <SplitPanel.Bottom>
                        <UserInfoGrid
                            userIds={this.state.selectedParticipantIds}
                        />
                    </SplitPanel.Bottom>
                </SplitPanel>
            </main>
        );
    }
}

export default CoursesPage;
