import React from 'react'
import * as PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {LocationFilters, CountryFilter} from "../models/LocationFilters";
import FetchHelper from "../helpers/FetchHelper";
import {withTranslation} from "react-i18next";

const emptyState = () => {
    return {
        data: {
            countries: [],
            regions: [],
            districts: [],
        }
    };
};

const ALL = 'ALL';
const isALL = (value) => { return value == null || value === ALL };
const fromALL = (value) => { return value === ALL ? null : value };

class LocationFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = emptyState();

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleDistrictChange = this.handleDistrictChange.bind(this);
    }

    handleCountryChange(event)
    {
        const country = event.target.value;

        let modified = this.props.filters.withCountry(fromALL(country));
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    handleRegionChange(event)
    {
        const region = event.target.value;

        let modified = this.props.filters.withRegion(fromALL(region));
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    handleDistrictChange(event)
    {
        const district = event.target.value;

        let modified = this.props.filters.withDistrict(fromALL(district));
        if (modified !== this.props.filters)
            this.props.onFilterChange(modified);
    }

    applyCountries(data) {
        this.setState((state, _props) => {
            return {
                data: {
                    countries: data,
                    regions: [],
                    districts: [],
                }
            };
        });
    }

    applyRegions(data) {
        this.setState((state, _props) => {
            return {
                data: {
                    countries: state.data.countries,
                    regions: data,
                    districts: [],
                }
            };
        });
    }

    applyDistricts(data) {
        this.setState((state, _props) => {
            return {
                data: {
                    countries: state.data.countries,
                    regions: state.data.regions,
                    districts: data,
                }
            };
        });
    }

    fetchCountries(filter) {
        FetchHelper.fetchJsonApi('/api/location/countries/'+filter, { method: 'GET' })
            .then(res => {
                console.log("[received] countries, filter: "+filter+": " + res.data.length);
                this.applyCountries(res.data);
            })
            .catch(err => {
                console.log(err);
                this.applyCountries([]);
                window.alert(err.message);
            });
    }

    fetchRegions(country) {
        if (isALL(country))
            this.applyRegions([]);
        else
            FetchHelper.fetchJsonApi('/api/location/'+country+'/regions', { method: 'GET' })
                .then(res => {
                    console.log("[received] regions, country: "+country+": " + res.data.length);
                    this.applyRegions(res.data);
                })
                .catch(err => {
                    console.log(err);
                    this.applyRegions([]);
                    window.alert(err.message);
                });
    }

    fetchDistricts(region) {
        if (isALL(region))
            this.applyDistricts([]);
        else
            FetchHelper.fetchJsonApi('/api/location/'+region+'/districts', { method: 'GET' })
                .then(res => {
                    console.log("[received] districts, region: "+region+": " + res.data.length);
                    this.applyDistricts(res.data);
                })
                .catch(err => {
                    console.log(err);
                    this.applyDistricts([]);
                    window.alert(err.message);
                });
    }

    componentDidMount() {
        this.fetchCountries(this.props.filterCountries);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.enableRegions &&
            this.props.filters.countryIso2 !== prevProps.filters.countryIso2) {
            this.fetchRegions(this.props.filters.countryIso2);
        } else
        if (this.props.enableDistricts &&
            this.props.filters.regionIso !== prevProps.filters.regionIso) {
            this.fetchDistricts(this.props.filters.regionIso);
        }
    }

    render() {
        const t = this.props.t;
        const selectedCountry = this.props.filters.countryIso2 ? this.props.filters.countryIso2 : ALL;
        const selectedRegion = this.props.filters.regionIso ? this.props.filters.regionIso : ALL;
        const selectedDistrict = this.props.filters.districtId ? this.props.filters.districtId : ALL;

        return (
            <Grid container direction="row" alignItems="center" spacing={2} wrap="nowrap">
                <Grid item>
                    <Select
                        id="select-country"
                        value={selectedCountry}
                        onChange={this.handleCountryChange}
                    >
                        <MenuItem key={ALL} value={ALL}>{t('All Countries')}</MenuItem>
                        {
                            this.state.data.countries.map(country => {
                                return (
                                    <MenuItem key={country.id} value={country.iso2}>
                                        {country.name}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </Grid>
                {
                    this.props.enableRegions && (
                        <Grid item>
                            <Select
                                id="select-region"
                                value={selectedRegion}
                                onChange={this.handleRegionChange}
                            >
                                <MenuItem key={ALL} value={ALL}>{t('All Regions')}</MenuItem>
                                {
                                    this.state.data.regions.map(region => {
                                        return (
                                            <MenuItem key={region.id} value={region.iso3166}>
                                                {region.name}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Grid>)
                }
                {
                    this.props.enableRegions && this.props.enableDistricts && (
                        <Grid item>
                            <Select
                                id="select-district"
                                value={selectedDistrict}
                                onChange={this.handleDistrictChange}
                            >
                                <MenuItem key={ALL} value={ALL}>{t('All Districts')}</MenuItem>
                                {
                                    this.state.data.districts.map(district => {
                                        return (
                                            <MenuItem key={district.id} value={district.id}>
                                                {district.name}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </Grid>
                    )
                }
            </Grid>
        );
    }
}


export default withTranslation("filters")(LocationFilter);

LocationFilter.propTypes = {
    filterCountries: PropTypes.string.isRequired,
    enableRegions: PropTypes.bool.isRequired,
    enableDistricts: PropTypes.bool.isRequired,
    filters: PropTypes.instanceOf(LocationFilters).isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

LocationFilter.defaultProps = {
    filterCountries: CountryFilter.All,
    enableSelectAny: true,
    enableRegions: true,
    enableDistricts: true,
    filters: LocationFilters.empty(),
    onFilterChange: null,
};