class FetchHelper {
    static apiUrl = process.env.REACT_APP_BASEAPI;

    static fetchJsonApi(url, request) {
        return this.fetchJson(FetchHelper.apiUrl+url, request);
    }

    static fetchJson(url, request) {
        request.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        request.credentials = 'include';

        return fetch(url, request)
            .then(res => {
                if (res.ok)
                    return res.json()
                        .then(data => {
                            return {
                                data: data,
                                headers: res.headers,
                            };
                        });
                else
                    return res.text()
                        .then(msg => {
                            let err = res.statusText;
                            let errMsg = res.statusText;
                            if (msg) {
                                err = JSON.parse(msg);
                                if (err.title)
                                    errMsg = err.title;
                            }

                            return Promise.reject({
                                status: res.status,
                                error: err,
                                message: "Received " + res.status + ": " + errMsg
                            });
                        });
            });
    }

    static badRequestError(message) {
        return {
            status: 400,
            error: "Skipped sending invalid request",
            message: message
        };
    }
}

export default FetchHelper