import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';


class MainMenu extends React.Component {
    render() {
        return (
            <IconButton edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
            </IconButton>
        );
    }
}

export default MainMenu;

MainMenu.propTypes = {
};